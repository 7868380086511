import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';

import { IObject } from '../../interfaces/IObject';

const initialFilter = {
  end_date: null,
  projects: null,
  title: null,
  groups: null,
  contract_type: null,
  contracting_parties: null,
  status: null,
};

interface IContextProps {
  activeTab: string;
  handleActiveTab: (value: string) => void;
  activeHeader: string;
  setActiveHeader: Dispatch<SetStateAction<string>>;
  filters: IObject;
  setFilters: Dispatch<SetStateAction<IObject>>;
  sorting: GridSortModel;
  setSorting: Dispatch<SetStateAction<GridSortModel>>;
}

const DocumentLibraryContext = React.createContext<IContextProps | null>(null);

export const useDocumentLibrary = (): IContextProps => {
  const context = React.useContext(DocumentLibraryContext);
  if (!context) {
    throw new Error(
      `useDocumentLibrary must be used within a DocumentLibraryProvider`
    );
  }

  const {
    activeTab,
    handleActiveTab,
    activeHeader,
    setActiveHeader,
    filters,
    setFilters,
    sorting,
    setSorting,
  } = context;

  return {
    activeTab,
    handleActiveTab,
    activeHeader,
    setActiveHeader,
    filters,
    sorting,
    setFilters,
    setSorting,
  };
};

interface IProps {
  children: React.ReactNode;
}

const DocumentLibraryProvider: FC<IProps> = (props) => {
  const [activeTab, setActiveTab] = useState('all');
  const [activeHeader, setActiveHeader] = useState<string>('active');
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [sorting, setSorting] = useState<GridSortModel>([]);

  const handleActiveTab = (value: any) => {
    switch (value) {
      case 'terminated': {
        setActiveTab('all');
        setActiveHeader('terminated');
        break;
      }
      case 'expired': {
        setActiveHeader('expired');
        setActiveTab('all');
        break;
      }
      case 'active': {
        setActiveTab('all');
        setActiveHeader('active');
        break;
      }
      case 'contracts_count':
      case 'all': {
        setActiveHeader('contracts_count');
        setActiveTab('all');
        break;
      }
      case 'expiring_this_quarter': {
        setActiveHeader('expiring_this_quarter');
        setActiveTab('all');
        break;
      }
      case 'expiring_next_quarter': {
        setActiveHeader('expiring_next_quarter');
        setActiveTab('all');
        break;
      }
      case 'expiring': {
        setActiveHeader('expiring');
        setActiveTab('all');
        break;
      }
      case 'under_renewal': {
        setActiveHeader('under_renewal');
        setActiveTab('all_under_renewal');
        break;
      }
      case 'total_contracts_size': {
        break;
      }
      default: {
        setActiveTab(value);
      }
    }
    setFilters(initialFilter);
  };

  const value = React.useMemo(
    () => ({
      activeTab,
      handleActiveTab,
      activeHeader,
      setActiveHeader,
      filters,
      setFilters,
      sorting,
      setSorting,
    }),
    [activeTab, activeHeader, filters, sorting]
  );

  return <DocumentLibraryContext.Provider value={value} {...props} />;
};

export default DocumentLibraryProvider;
