import React, { useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Controller } from 'react-hook-form';

import ClauseLibraryDrawer from '../ClauseLibrary/ClauseLibraryDrawer';

const chipStyle = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 500,
  background: '#FFECF1',
  padding: '15px 10px',
  borderRadius: '5px',
  color: '#6D264C',
  maxWidth: '650px',
  overflowWrap: 'break-word',
};

interface IProps {
  clauseValue: any;
  handleClauseValue: any;
  name: string;
  control?: any;
  readOnly?: boolean;
  isDescribeCondition?: boolean;
}

const LinkCLauseTextField = React.forwardRef<IProps, any>((props) => {
  const {
    clauseValue,
    handleClauseValue,
    name,
    control,
    readOnly,
    isDescribeCondition,
  } = props;

  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [clauseToEdit, setClauseToEdit] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedClause, setSelectedClause] = useState<any>([]);
  const [openClauseDrawer, setOpenClauseDrawer] = useState<boolean>(false);

  const handleSelectClause = (value: any) => {
    let newClauseValue = [...clauseValue];
    newClauseValue = clauseValue.filter(
      (data: any) => data.type !== 'clause_library'
    );
    value.map((data: any) => {
      return newClauseValue.push(data);
    });
    handleClauseValue(newClauseValue);
    setSelectedClause(value);
  };

  const handleClickOpen = (clause: any) => {
    setOpen(true);
    setClauseToEdit(clause);
  };
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);

    setClauseToEdit(null);
  };
  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleClauseChange = React.useCallback((event: any) => {
    setClauseToEdit((prev: any) => ({
      ...prev,
      clause_text: event.target.value,
    }));
  }, []);

  const handleSave = React.useCallback(() => {
    const newClauseValue = clauseValue;
    const index = newClauseValue.findIndex(
      (data: any) => data.id === clauseToEdit.id
    );
    if (clauseToEdit) {
      index !== -1 &&
        (newClauseValue[index].clause_text = clauseToEdit.clause_text);
    }
    handleClauseValue(newClauseValue);
    setIsEdit(false);
    setOpen(false);
  }, [clauseToEdit, clauseValue, handleClauseValue]);

  const handleChange = React.useCallback(
    (event: any, newValue: any) => {
      if (isDescribeCondition && newValue.length > 1) {
        return;
      }
      for (let i = 0; i < newValue.length; i++) {
        if (typeof newValue[i] === 'string') {
          const newObj = {
            id: Date.now(),
            clause_name: newValue[i],
            clause_text: newValue[i],
          };
          const newClauseValue: any = clauseValue || [];
          const index = newClauseValue.findIndex(
            (data: any) => data.clause_name === newValue[i]
          );
          if (index === -1) {
            newClauseValue.push(newObj);
          }
          handleClauseValue(newClauseValue);
        }
      }
      if (isDescribeCondition && newValue.length === 1) {
        setIsInputDisabled(true);
      }
    },
    [handleClauseValue, clauseValue, isDescribeCondition]
  );

  const handleDelete = React.useCallback(
    (id: any) => {
      const clause = [...clauseValue];
      const index = clause.findIndex((data: any) => data.id === id);
      if (index > -1) {
        clause.splice(index, 1);
      }
      handleClauseValue(clause);
      if (isDescribeCondition && clause.length === 0) {
        setIsInputDisabled(false);
      }
    },
    [clauseValue, handleClauseValue, isDescribeCondition]
  );

  const handleInputChange = React.useCallback((event: any, newValue: any) => {
    if (newValue.length) {
      setIsChanging(true);
    } else {
      setIsChanging(false);
    }
  }, []);

  const inputValue = React.useMemo(() => {
    return clauseValue;
  }, [clauseValue]);

  return (
    <Stack direction="row" gap={2}>
      <Stack sx={{ width: '85%' }}>
        <Controller
          name={name}
          control={control}
          rules={{
            required:
              clauseValue?.length === 0 ? 'This field is required' : false,
          }}
          render={({ fieldState }) => (
            <>
              <Autocomplete
                readOnly={readOnly}
                value={inputValue}
                clearIcon={false}
                options={[]}
                freeSolo
                multiple
                onChange={handleChange}
                onInputChange={handleInputChange}
                disabled={isInputDisabled}
                renderTags={(value, props) => {
                  return (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {value?.map((option: any, index) => (
                        <Chip
                          key={index}
                          sx={chipStyle}
                          label={option.clause_text}
                          onDelete={() => handleDelete(option.id)}
                          icon={
                            option.type ? (
                              <FolderOutlinedIcon sx={{ fontSize: '18px' }} />
                            ) : (
                              <EditOutlinedIcon sx={{ fontSize: '18px' }} />
                            )
                          }
                          onClick={() => handleClickOpen(option)}
                          {...props}
                        />
                      ))}
                    </Stack>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    label={
                      isDescribeCondition
                        ? 'Add description *'
                        : 'Add a value to match *'
                    }
                    {...params}
                    error={!!fieldState?.error}
                    helperText={
                      fieldState?.error ? fieldState?.error.message : ''
                    }
                  />
                )}
              />
            </>
          )}
        />

        <FormHelperText>
          {isChanging ? (
            <Typography variant="caption" sx={{ color: 'riError.500' }}>
              {isDescribeCondition
                ? 'Press enter to save the description value'
                : 'Press Enter to store your custom clause/value'}
            </Typography>
          ) : isDescribeCondition ? (
            'You can enter to save the description value.'
          ) : (
            'You can enter custom values/clauses here. Press enter after every custom clause to store them. Click on any clause above to view/edit'
          )}
        </FormHelperText>
      </Stack>
      {!readOnly && !isDescribeCondition && (
        <Box sx={{ alignSelf: 'flex-start' }}>
          <Button
            onClick={() => {
              setOpenClauseDrawer(true);
            }}
            sx={{
              display: 'flex',
              whiteSpace: 'nowrap',
              padding: 0,
            }}
          >
            <FolderIcon /> &nbsp; Link a clause
          </Button>
        </Box>
      )}
      {open && clauseToEdit && (
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-paper': {
              width: '312px',
            },
          }}
        >
          <DialogTitle>
            {clauseToEdit.type
              ? clauseToEdit.clause_name
              : clauseToEdit.clause_name.substring(0, 2)}
          </DialogTitle>
          <DialogContent>
            {isEdit ? (
              <TextField
                autoFocus
                value={clauseToEdit.clause_text}
                margin="dense"
                id="name"
                label="Edit Clause"
                onChange={handleClauseChange}
                fullWidth
              />
            ) : (
              <DialogContentText>{clauseToEdit.clause_text}</DialogContentText>
            )}
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: 'flex-start', padding: '8px 16px' }}
          >
            <Button onClick={handleClose} sx={{ padding: 0, minWidth: '45px' }}>
              Close
            </Button>
            {clauseToEdit.type ? null : isEdit ? (
              <Button
                onClick={handleSave}
                sx={{ padding: 0, minWidth: '45px' }}
              >
                Save
              </Button>
            ) : (
              <Button
                onClick={handleEdit}
                sx={{ padding: 0, minWidth: '45px' }}
              >
                Edit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {openClauseDrawer && (
        <ClauseLibraryDrawer
          open={openClauseDrawer}
          onClose={() => setOpenClauseDrawer(false)}
          selectedClause={selectedClause}
          handleSelectedValue={(value) => handleSelectClause(value)}
        />
      )}
    </Stack>
  );
});

LinkCLauseTextField.displayName = 'LinkCLauseTextField';

export default LinkCLauseTextField;
