import { FC, useEffect } from 'react';
import React from 'react';

import { Add } from '@mui/icons-material';
import PercentIcon from '@mui/icons-material/Percent';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import DeleteIcon from '../../../RiverusUI/Components/Icons/DeleteIcon';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';

interface IProps {
  dataType: any;
  dataList: any;
  fieldData?: any;
  isEdit: boolean;
}

const currencyList = [
  { id: 1, name: 'SR Saudi riyal' },
  { id: 2, name: '£ Saint Helenian Pound' },
  { id: 3, name: '₹ Indian Rupee' },
  { id: 4, name: '£ Pound Sterling' },
  { id: 5, name: '$ Dollar' },
  { id: 6, name: '￥ Japanese yen' },
  { id: 7, name: '€ Euro' },
];

const MapField: FC<IProps> = (props) => {
  const { dataType, dataList, fieldData, isEdit } = props;

  const { control, watch, resetField } = useFormContext();

  const optionalValue = watch('optional_value');

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'optional_data',
  });

  useEffect(() => {
    if (fieldData?.optional_data) {
      replace(fieldData.optional_data);
    } else {
      if (dataType) {
        resetField('default_field_unit');
        resetField('default_field_value');
        resetField('value1');
        resetField('value2');
        resetField('lower_limit');
        resetField('upper_limit');
        resetField('max_date');
        resetField('min_date');
      }
      switch (dataType.custom_data_type) {
        case 'Bulleted List':
        case 'Numbered List': {
          replace({
            display_name: '',
            data_type_id: '',
          });
          remove(0);
          break;
        }

        case 'Boolean': {
          remove(0);
          replace({
            value1: null,
            value2: null,
          });
          break;
        }
        default: {
          replace([{ name: '' }]);
          remove(0);
          break;
        }
      }
    }
  }, [dataType, fieldData, remove, replace, resetField]);

  switch (dataType.custom_data_type) {
    case 'Currency': {
      return (
        <Stack gap={1}>
          <RISelectComponent
            name="default_field_unit"
            control={control}
            options={currencyList}
            valueKey="name"
            label="Currency"
            readOnly={!isEdit}
          />
        </Stack>
      );
    }
    case 'Boolean': {
      return (
        <>
          {fields.map((field: any, index) => (
            <Stack gap={1} key={field.id}>
              <ControlledTextField
                key={field.id}
                name={`optional_data[${index}].value1`}
                label="value1"
                control={control}
                required
                inputProps={{
                  readOnly: !isEdit,
                }}
              />
              <ControlledTextField
                key={field.id}
                name={`optional_data[${index}].value2`}
                label="value2"
                control={control}
                required
                inputProps={{
                  readOnly: !isEdit,
                }}
              />
            </Stack>
          ))}
        </>
      );
    }
    case 'Select': {
      return (
        <Stack gap={1}>
          {fields.map((field: any, index: number) => (
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '80%',
                borderBottom: '1px solid #CAC4D0',
                minHeight: '40px',
              }}
            >
              {field.name}
              {isEdit && (
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
          ))}
          {isEdit && (
            <Stack direction="row">
              <ControlledTextField name="optional_value" />
              <Button
                variant="text"
                startIcon={<Add />}
                onClick={() => {
                  if (optionalValue) {
                    append({
                      name: optionalValue,
                    });
                    resetField('optional_value');
                  }
                }}
              >
                Add to selection
              </Button>
            </Stack>
          )}
        </Stack>
      );
    }
    case 'Bulleted List':
    case 'Numbered List': {
      return (
        <>
          {fields.map((field: any, index) => (
            <Stack key={index} gap={1}>
              <Stack direction="row" alignItems="center" gap={1}>
                <ControlledTextField
                  key={field.id}
                  name={`optional_data[${index}].display_name`}
                  control={control}
                  inputProps={{
                    readOnly: !isEdit,
                  }}
                />
                <Box sx={{ width: '50%' }}>
                  <RISelectComponent
                    key={field.id}
                    control={control}
                    label="Field type"
                    options={dataList || []}
                    labelKey="custom_data_type"
                    name={`optional_data[${index}].data_type_id`}
                    readOnly={!isEdit}
                  />
                </Box>
                {isEdit && (
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          ))}
          {isEdit && (
            <Stack direction="row">
              <Button
                variant="text"
                startIcon={<Add />}
                onClick={() => {
                  append({
                    display_name: '',
                    data_type: '',
                  });
                }}
              >
                Add
              </Button>
            </Stack>
          )}
        </>
      );
    }
    case 'Percentage': {
      return (
        <ControlledTextField
          name="default_field_value"
          control={control}
          fullWidth
          type="number"
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PercentIcon />
              </InputAdornment>
            ),
            readOnly: !isEdit,
          }}
        />
      );
    }
    case 'Duration': {
      return (
        <Stack direction="row" alignItems="center">
          <Controller
            name="default_field_unit"
            control={control}
            render={({ field }) => (
              <FormControl style={{ margin: '10px', width: '50%' }}>
                <InputLabel htmlFor="Select-duration">Duration</InputLabel>
                <Select
                  {...field}
                  required
                  label="Duration"
                  variant="outlined"
                  inputProps={{
                    id: 'Select-duration',
                    readOnly: !isEdit,
                  }}
                >
                  <MenuItem key={'day'} value={'days'}>
                    Day(s)
                  </MenuItem>
                  <MenuItem key={'Month'} value={'Months'}>
                    Month(s)
                  </MenuItem>
                  <MenuItem key={'Years'} value={'Years'}>
                    Years(s)
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <ControlledTextField
            name="default_field_value"
            control={control}
            type="number"
            label="Enter value"
            inputProps={{
              readOnly: !isEdit,
            }}
          />
        </Stack>
      );
    }
    default:
      return null;
  }
};

export default MapField;
