import React, { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import TagIcon from '@mui/icons-material/Tag';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import ContractingParties from './ContractingParties';
import ContractsConfirm from './ContractsConfirm';
import { useUserData } from '../../../App/Component/UserDataProvider';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { fetchContractType, fetchGroups } from '../../../Services/Approval';
import {
  addProject,
  editReplaceTags,
  fetchProjects,
} from '../../../Services/DocumentLibrary';

interface Props {
  contractData: any;
}

const ContractDetails: React.FC<Props> = ({ contractData }) => {
  const [editContractType, setEditContractType] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<boolean>(false);
  const [editProject, setEditProject] = useState<boolean>(false);
  const [openContractConfirm, setOpenContractConfirm] =
    useState<boolean>(false);
  const [filterType, setFilterType] = useState<string>('');
  const [filterName, setFilterName] = useState<string>('');
  const [isAddNewProject, setIsAddNewProject] = useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);

  const { handleSubmit, control, watch, resetField, setValue } = useForm();

  const queryClient = useQueryClient();
  const { user_id, userIsAdmin } = useUserData();

  const project_name = watch('projectName') || '';

  const { data: contractTypes, isLoading: contractLoading } = useQuery({
    queryKey: ['contract_types'],
    queryFn: fetchContractType,
    select: (response: any) => sortListAlphabetical(response?.results),
  });

  const { data: projectData, isLoading: projectLoading } = useQuery({
    queryKey: ['project_data'],
    queryFn: async () => await fetchProjects(),
    select: (response: any) =>
      response.results.filter((data: any) => data.name !== ''),
  });

  const { data: groupData, isLoading: groupLoading } = useQuery({
    queryKey: ['group_data'],
    queryFn: fetchGroups,
  });

  useEffect(() => {
    if (contractData) {
      const getContractTypeId = contractData?.contract_type?.[0]?.id;
      const getProjectIds = contractData?.projects?.map(
        (item: any) => item?.id
      );
      const filterGroups = groupData?.filter((item: any) => {
        if (contractData?.groups?.includes(item?.name)) {
          return item?.id;
        }
      });
      const getGroupIds = filterGroups?.map((item: any) => item?.id);
      setValue('groups', getGroupIds);
      setValue('contract_type', getContractTypeId);
      setValue('projects', getProjectIds);
    }
  }, [contractData, groupData]);

  const { mutate: addNewProject, isPending: addProjectLoading } = useMutation({
    mutationKey: ['add_new_projects'],
    mutationFn: async (payload: any) => addProject(payload),

    onSuccess: () => {
      enqueueSnackbar('Project added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setIsAddNewProject(false);
      resetField('projectName');
      queryClient.invalidateQueries({ queryKey: ['project_data'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to add project!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddProject = () => {
    const payload = [
      {
        comment: '',
        displayName: project_name,
        name: project_name,
      },
    ];
    addNewProject(payload);
  };

  const handleCloseConfirmation = () => {
    setOpenContractConfirm(false);
    setFilterType('');
    setFilterName('');
  };

  const handleOpenConfirmation = (type: string, typeName: string) => {
    setOpenContractConfirm(true);
    setFilterType(type);
    setFilterName(typeName);
  };

  const { mutate: editContractDetails, isPending: editContractLoading } =
    useMutation({
      mutationKey: ['edit_contract_details'],
      mutationFn: async (payload: any) => editReplaceTags(payload),
      onSuccess: () => {
        enqueueSnackbar('Contract details edited successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setEditContractType(false);
        setEditGroup(false);
        setEditProject(false);
        queryClient.invalidateQueries({
          queryKey: ['get_contract_data_by_id'],
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to update tags!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const onSubmit = (data: any) => {
    const payload = {
      contract_ids: [contractData?.id],
      ...data,
    };
    if (payload?.contract_type) {
      payload.contract_type = [payload?.contract_type];
    }
    if (data?.groups?.length > 0) {
      editContractDetails(payload);
    } else {
      enqueueSnackbar('Please select any one group', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const isOwner = useMemo(
    () => contractData?.creator_details?.id === user_id,
    [contractData?.creator_details, user_id]
  );

  const userIsCoOwner = useMemo(
    () => contractData?.owners?.find((owner: any) => owner?.id === user_id),
    [contractData?.owners, user_id]
  );

  const userCanDoAction = useMemo(
    () => isOwner || userIsAdmin || userIsCoOwner,
    [isOwner, userIsAdmin, userIsCoOwner]
  );

  const handleEditContractType = () => {
    if (userCanDoAction) {
      setEditContractType(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleEditGroups = () => {
    if (userCanDoAction) {
      setEditGroup(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleEditProjects = () => {
    if (userCanDoAction) {
      setEditProject(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Accordion
        sx={{
          padding: 0,
        }}
        className="according-class"
        expanded={accordionExpanded}
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Contract Details
        </AccordionSummary>
        <AccordionDetails>
          <Stack width="100%" spacing={2}>
            {editContractType ? (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2} width="100%">
                    <Typography fontWeight={600}>Edit Contract Type</Typography>
                    <RISelectComponent
                      name="contract_type"
                      control={control}
                      label="Contract Type"
                      options={contractTypes}
                      loading={contractLoading}
                      labelKey="displayName"
                      valueKey="id"
                      renderCustomComponent={(value: any, props) => (
                        <CustomChip
                          {...props}
                          label={value?.displayName}
                          icon={
                            <TagIcon
                              style={{
                                color: '#6D264C',
                              }}
                            />
                          }
                          style={{
                            background: '#88305F24',
                          }}
                        />
                      )}
                    />
                    <Stack direction="row">
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        startIcon={<CheckIcon />}
                        loading={editContractLoading}
                      >
                        Save
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        onClick={() => setEditContractType(false)}
                        startIcon={<CloseIcon />}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            ) : (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <Stack
                  width="100%"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Typography fontWeight={600}>Contract Type</Typography>
                  <Button
                    disabled={contractData?.terminate_status}
                    variant="text"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    style={{ padding: 0 }}
                    onClick={handleEditContractType}
                  >
                    Edit
                  </Button>
                </Stack>
                {contractData?.contract_type?.map(
                  (item: any, index: number) => (
                    <Chip
                      key={index}
                      label={item?.displayName}
                      style={{ background: '#C4DBFF', cursor: 'pointer' }}
                      onClick={() =>
                        handleOpenConfirmation(
                          'contract_type',
                          item?.displayName
                        )
                      }
                    />
                  )
                )}
              </Box>
            )}

            {editGroup ? (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2} width="100%">
                    <Typography fontWeight={600}>Edit Group</Typography>
                    <RISelectComponent
                      name="groups"
                      control={control}
                      label="Groups *"
                      options={sortListAlphabetical(groupData)}
                      loading={groupLoading}
                      isMultiselect={true}
                      valueKey="id"
                      renderCustomComponent={(value: any, props) => (
                        <CustomChip
                          {...props}
                          icon={
                            <GroupWorkOutlinedIcon
                              style={{
                                color: '#6D264C',
                              }}
                            />
                          }
                          label={value?.name}
                          style={{
                            background: '#88305F24',
                          }}
                        />
                      )}
                    />
                    <Stack direction="row">
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        startIcon={<CheckIcon />}
                        loading={editContractLoading}
                      >
                        Save
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        onClick={() => setEditGroup(false)}
                        startIcon={<CloseIcon />}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            ) : (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <Stack
                  width="100%"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Typography fontWeight={600}>Group</Typography>
                  <Button
                    disabled={contractData?.terminate_status}
                    variant="text"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    style={{ padding: 0 }}
                    onClick={handleEditGroups}
                  >
                    Edit
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  width="100%"
                  spacing={1}
                  flexWrap="wrap"
                  gap="6px"
                >
                  {contractData?.groups?.map((item: any, index: number) => (
                    <Chip
                      key={index}
                      label={item}
                      style={{ background: '#DCBDE7', cursor: 'pointer' }}
                      onClick={() => handleOpenConfirmation('groups', item)}
                    />
                  ))}
                </Stack>
              </Box>
            )}

            {editProject ? (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2} width="100%">
                    <Typography fontWeight={600}>Edit Project</Typography>
                    <RISelectComponent
                      name="projects"
                      control={control}
                      label="Project"
                      options={sortListAlphabetical(projectData)}
                      loading={projectLoading}
                      isMultiselect={true}
                      renderCustomComponent={(value: any, props) => (
                        <CustomChip
                          {...props}
                          icon={
                            <WorkOutlineIcon
                              style={{
                                color: '#6D264C',
                              }}
                            />
                          }
                          label={value?.name}
                          style={{
                            background: '#88305F24',
                          }}
                        />
                      )}
                    />
                    {isAddNewProject && (
                      <ControlledTextField
                        name="projectName"
                        control={control}
                        label="Project Name"
                        fullWidth
                      />
                    )}

                    <Stack
                      direction="row"
                      marginTop={3}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row">
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          startIcon={<CheckIcon />}
                          loading={editContractLoading}
                        >
                          Save
                        </LoadingButton>
                        <Button
                          variant="outlined"
                          onClick={() => setEditProject(false)}
                          startIcon={<CloseIcon />}
                        >
                          Cancel
                        </Button>
                      </Stack>
                      {!isAddNewProject ? (
                        <Button
                          startIcon={<AddIcon />}
                          onClick={() => setIsAddNewProject(true)}
                        >
                          Add project
                        </Button>
                      ) : (
                        <LoadingButton
                          loading={addProjectLoading}
                          variant="outlined"
                          onClick={() => handleAddProject()}
                          disabled={!project_name.trim()}
                        >
                          Save project
                        </LoadingButton>
                      )}
                    </Stack>
                  </Stack>
                </form>
              </Box>
            ) : (
              <Box
                style={{
                  background: '#FFECF1',
                  borderRadius: '10px',
                  padding: '10px',
                }}
              >
                <Stack
                  width="100%"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Typography fontWeight={600}>Project</Typography>
                  <Button
                    disabled={contractData?.terminate_status}
                    variant="text"
                    startIcon={<ModeEditOutlineOutlinedIcon />}
                    style={{ padding: 0 }}
                    onClick={handleEditProjects}
                  >
                    Edit
                  </Button>
                </Stack>
                <Stack direction="row" width="100%" spacing={1} flexWrap="wrap">
                  {contractData?.projects?.map((item: any, index: number) => (
                    <Chip
                      key={index}
                      label={item?.name}
                      style={{ background: '#CDE7BD', cursor: 'pointer' }}
                      onClick={() =>
                        handleOpenConfirmation('projects', item?.name)
                      }
                    />
                  ))}
                </Stack>
              </Box>
            )}
            <ContractingParties
              contractId={contractData?.id}
              isTerminated={contractData?.terminate_status}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      {openContractConfirm && (
        <ContractsConfirm
          open={openContractConfirm}
          onClose={handleCloseConfirmation}
          type={filterType}
          typeName={filterName}
        />
      )}
    </React.Fragment>
  );
};

export default ContractDetails;
