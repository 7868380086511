import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import { createUser, editUser } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  editDetails?: any;
  usersTableKey?: any;
  setUsersTableKey?: any;
  setFilters?: any;
  initialFilter?: any;
}

const CreateUsersModal: React.FC<Props> = ({
  open,
  onClose,
  editDetails,
  usersTableKey,
  setUsersTableKey,
  setFilters,
  initialFilter,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    mode: 'onChange',
  });
  const queryClient = useQueryClient();
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    if (editDetails) {
      setValue('id', editDetails?.keycloak_id);
      setValue('username', editDetails?.username);
      setValue('first_name', editDetails?.first_name);
      setValue('last_name', editDetails?.last_name);
      setValue('email', editDetails?.email);
    }
  }, [editDetails, setValue]);

  const { mutate: createUserMutation, isPending: loadingCreateUser } =
    useMutation({
      mutationFn: createUser,
      onSuccess: () => {
        onClose();
        setUsersTableKey(Math.random());
        setFilters(initialFilter);
        enqueueSnackbar('User created successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.errorMessage;
        enqueueSnackbar(`${responseData || 'Failed to create User!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: editUserMutation, isPending: loadingEditUser } = useMutation({
    mutationFn: editUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'all-users',
          usersTableKey,
        ],
      });
      enqueueSnackbar('User edited successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to edit User!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = (data: any) => {
    if (!editDetails) {
      const payload = {
        username: data?.username,
        email: data?.email,
        firstName: data?.first_name,
        lastName: data?.last_name,
        enabled: true,
      };
      createUserMutation(payload);
    } else {
      const payload = {
        id: editDetails?.keycloak_id,
        body: {
          username: editDetails?.username,
          email: editDetails?.email,
          firstName: data?.first_name,
          lastName: data?.last_name,
          enabled: true,
        },
      };
      editUserMutation(payload);
    }
  };

  return (
    <CustomModal
      title={`${editDetails ? (!editDetails.is_active ? 'Inactive User Details' : 'Edit User') : 'Create User'} `}
      maxWidth="lg"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} width="600px" sx={{ padding: '30px 15px 15px' }}>
            <ControlledTextField
              name="username"
              control={control}
              required
              label="Username"
              fullWidth
              disabled={!!editDetails}
              rules={{
                required: 'Username is required',
                validate: (value) => {
                  const hasSpace = /\s/.test(value);
                  const hasSpecialChar =
                    /[@!_#-$%^&*(),.?":[\]{}|<>~`+=\-\\'/]/.test(value);
                  if (hasSpace && hasSpecialChar) {
                    return 'Username must not contain spaces and special characters.';
                  }
                  if (hasSpace) {
                    return 'Username must not contain spaces.';
                  }
                  if (hasSpecialChar) {
                    return 'Username must not contain special characters.';
                  }
                  return true;
                },
                minLength: {
                  value: 3,
                  message: 'Username must be at least 3 characters long.',
                },
                maxLength: {
                  value: 20,
                  message: 'Username must be at most 20 characters long',
                },
              }}
              error={!!methods.formState.errors.username}
              helperText={`${methods.formState.errors.username?.message || ''}`}
              onChange={(e) => {
                const newValue = e?.target?.value?.toLowerCase();
                methods.setValue('username', newValue, {
                  shouldValidate: true,
                });
              }}
            />
            <ControlledTextField
              name="email"
              control={control}
              label="Email"
              fullWidth
              type="email"
              required
              disabled={!!editDetails}
              rules={{
                required: 'Email is required',
                validate: (value) => {
                  const hasSpace = /\s/.test(value);
                  const isInvalidFormat = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                    value
                  );
                  if (hasSpace && isInvalidFormat) {
                    return 'Email must not contain spaces & must be a valid email address.';
                  }
                  if (hasSpace) {
                    return 'Email must not contain spaces.';
                  }
                  if (isInvalidFormat) {
                    return 'Enter a valid email address.';
                  }
                  return true;
                },
              }}
              error={!!methods.formState.errors.email}
              helperText={`${methods.formState.errors.email?.message || ''}`}
              onChange={(e) => {
                const newValue = e?.target?.value?.toLowerCase();
                methods.setValue('email', newValue, {
                  shouldValidate: true,
                });
              }}
            />
            <Stack direction="row" spacing={2}>
              <ControlledTextField
                name="first_name"
                control={control}
                required
                label="First Name"
                fullWidth
                disabled={editDetails && !editDetails?.is_active}
              />
              <ControlledTextField
                name="last_name"
                control={control}
                required
                label="Last Name"
                fullWidth
                disabled={editDetails && !editDetails?.is_active}
              />
            </Stack>
            <Stack direction="row" width="100%" spacing={2}>
              <Tooltip
                title={
                  editDetails && !editDetails?.is_active
                    ? "This action can't be performed because the user is inactive."
                    : ''
                }
                arrow
              >
                <Stack>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={loadingCreateUser || loadingEditUser}
                    disabled={editDetails && !editDetails?.is_active}
                  >
                    {editDetails ? 'Save' : 'Create'}
                  </LoadingButton>
                </Stack>
              </Tooltip>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateUsersModal;
