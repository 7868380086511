import React, { useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { IObject } from '../../../interfaces/IObject';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import colors from '../../../RiverusUI/Theme/colors';
import { fetchExtractedData } from '../../../Services/Insights';
import { handleSeeMoreAndLess, highlightedSentence } from '../Helper';
import LoadingInsights from './LoadingInsights';

interface Props {
  draftData: any;
  instance: any;
  isLoadingCombinedData: any;
  isFetchingCombinedData: any;
  combinedData: any;
  clauseType: any;
  draftDataLoading: any;
}

export let allInsightsList: any = []; // this is for issue tab

const InsightsTab: React.FC<Props> = ({
  draftData,
  instance,
  isLoadingCombinedData,
  isFetchingCombinedData,
  combinedData,
  clauseType,
  draftDataLoading,
}) => {
  const [visibleSentences, setVisibleSentences] = useState<IObject>({});
  const [selectedSentence, setSelectedSentence] = useState<string | null>(null);
  const [showFullText, setShowFullText] = useState<any>({
    id: '',
    show: false,
  });
  const [activeTabText, setActiveTabText] = useState<string | null>('summary');

  const { data: extractedData, isFetching: isFetchingExtractedData } = useQuery(
    {
      queryKey: ['clause_term', draftData?.id, clauseType],
      queryFn: async () => await fetchExtractedData(draftData?.id),
      enabled: !!draftData?.id && !!clauseType?.length,
    }
  );

  allInsightsList = combinedData;

  const allExtractedData = extractedData?.raw_content?.hierarchy;

  const sentenceData = combinedData?.filter((data: any) => {
    if (data?.clauseData && data?.clauseData.length > 0) {
      const getSentences = data?.clauseData.map((item: any) => {
        if (!item.sentence) {
          allExtractedData?.find((data: any) => {
            if (item.para_id === data.index) {
              item.sentence = data.para;
            }
          });
        }
        return item;
      });
      return getSentences;
    }
  });

  const handleToggleSentences = (clauseType: string) => {
    setVisibleSentences((prevVisibleSentences) => ({
      [clauseType]: !prevVisibleSentences[clauseType],
    }));
  };

  const handleSentenceClick = (sentence: string) => {
    setSelectedSentence(sentence);
    highlightedSentence(sentence, instance);
  };

  const handleToggle = (id: string) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    tabValue: string
  ) => {
    setActiveTabText(tabValue);
  };

  const isLoading = isLoadingCombinedData || draftDataLoading;
  const isFetching = isFetchingCombinedData || isFetchingExtractedData;

  if (isLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }
  return (
    <Stack
      my={2}
      mx={1}
      p={2}
      borderRadius="10px"
      sx={{
        backgroundColor: colors?.riPrimary[10],
        overflowY: 'auto',
        height: '81vh',
      }}
    >
      {isFetching ? (
        <LoadingInsights />
      ) : (
        sentenceData?.map((singleData: any) => (
          <Box
            key={singleData.clauseType}
            px={2}
            py={1}
            mb={2}
            borderRadius="10px"
            sx={{ backgroundColor: colors?.riPrimary[20] }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={
                visibleSentences[singleData.clauseType] &&
                draftData?.extraction_type === 'ai'
                  ? '15px'
                  : 0
              }
            >
              <Typography
                fontSize="16px"
                fontWeight="500"
                textTransform="capitalize"
              >
                {singleData?.clauseType}
              </Typography>
              {draftData?.extraction_type === 'ai' && (
                <ToggleButtonGroup
                  color="primary"
                  value={activeTabText}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    border: '2px solid #6D264C',
                    borderRadius: '20px',
                    width: 'fit-content',
                    height: '1.5rem',
                    whiteSpace: 'nowrap',
                    padding: '2px',
                    background: '#f1eaed',
                  }}
                >
                  <ToggleButton
                    value="summary"
                    sx={{
                      borderRadius: '16px !important',
                      border: 'unset',
                      color:
                        activeTabText === 'summary'
                          ? '#fff !important'
                          : '#6D264C !important',
                      backgroundColor:
                        activeTabText === 'summary' ? '#6D264C !important' : '',
                      height: '1.5rem !important',
                      fontSize: '12px !important',
                    }}
                  >
                    Summary
                  </ToggleButton>
                  <ToggleButton
                    value="clauseText"
                    sx={{
                      borderRadius: '16px !important',
                      border: 'unset',
                      color:
                        activeTabText === 'clauseText'
                          ? '#fff !important'
                          : '#6D264C !important',
                      backgroundColor:
                        activeTabText === 'clauseText'
                          ? '#6D264C !important'
                          : '',
                      height: '1.5rem !important',
                      fontSize: '12px !important',
                    }}
                  >
                    Clause Text
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {draftData?.extraction_type === 'ai' ? (
                  <Typography fontSize="11px" fontWeight="500">
                    {activeTabText === 'summary'
                      ? singleData?.summary?.length
                      : singleData?.clauseData?.length}
                  </Typography>
                ) : (
                  <Typography fontSize="11px" fontWeight="500">
                    {singleData?.clauseData?.length}
                  </Typography>
                )}
                {visibleSentences[singleData.clauseType] ? (
                  <IconButton
                    onClick={() => handleToggleSentences(singleData.clauseType)}
                  >
                    <KeyboardArrowUp />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleToggleSentences(singleData.clauseType)}
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                )}
              </Box>
            </Stack>
            {draftData?.extraction_type === 'ai' ? (
              <>
                {activeTabText === 'summary' &&
                  singleData?.summary?.map((item: any) => (
                    <Stack
                      key={item}
                      marginBottom={2}
                      padding={2}
                      borderRadius="10px"
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedSentence === item.sentence
                            ? colors?.riTertiary[10] // Change the background color for selected sentence
                            : colors?.riPrimary[10],
                        border:
                          selectedSentence === item.sentence
                            ? `1px solid ${colors?.riPrimary.main}` // Add border for selected sentence
                            : 'none',
                        display: visibleSentences[singleData.clauseType]
                          ? 'block'
                          : 'none',
                      }}
                      // onClick={() => handleSentenceClick(item.sentence)}
                    >
                      <Typography fontWeight="400">{item}</Typography>
                    </Stack>
                  ))}
                {activeTabText === 'clauseText' &&
                  singleData?.clauseData?.map((item: any) => (
                    <Stack
                      key={item.sentence}
                      marginBottom={2}
                      padding={2}
                      borderRadius="10px"
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedSentence === item.sentence
                            ? colors?.riTertiary[10] // Change the background color for selected sentence
                            : colors?.riPrimary[10],
                        border:
                          selectedSentence === item.sentence
                            ? `1px solid ${colors?.riPrimary.main}` // Add border for selected sentence
                            : 'none',
                        display: visibleSentences[singleData.clauseType]
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => handleSentenceClick(item.sentence)}
                    >
                      <Typography fontWeight="400">
                        {showFullText?.id === item?.para_id &&
                        showFullText?.show
                          ? item.sentence
                          : handleSeeMoreAndLess(item.sentence)}
                        {item?.sentence?.split(/\s+/)?.length > 20 && (
                          <span
                            onClick={() => handleToggle(item?.para_id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {showFullText?.id === item?.para_id &&
                            showFullText?.show
                              ? ' See less'
                              : 'See more'}
                          </span>
                        )}
                      </Typography>
                    </Stack>
                  ))}
              </>
            ) : (
              singleData?.clauseData?.map((item: any) => (
                <Stack
                  key={item.sentence}
                  marginBottom={2}
                  padding={2}
                  borderRadius="10px"
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedSentence === item.sentence
                        ? colors?.riTertiary[10] // Change the background color for selected sentence
                        : colors?.riPrimary[10],
                    border:
                      selectedSentence === item.sentence
                        ? `1px solid ${colors?.riPrimary.main}` // Add border for selected sentence
                        : 'none',
                    display: visibleSentences[singleData.clauseType]
                      ? 'block'
                      : 'none',
                  }}
                  onClick={() => handleSentenceClick(item.sentence)}
                >
                  <Typography fontWeight="400">
                    {showFullText?.id === item?.para_id && showFullText?.show
                      ? item.sentence
                      : handleSeeMoreAndLess(item.sentence)}
                    {item?.sentence?.split(/\s+/)?.length > 20 && (
                      <span
                        onClick={() => handleToggle(item?.para_id)}
                        style={{ cursor: 'pointer' }}
                      >
                        {showFullText?.id === item?.para_id &&
                        showFullText?.show
                          ? ' See less'
                          : 'See more'}
                      </span>
                    )}
                  </Typography>
                </Stack>
              ))
            )}
          </Box>
        ))
      )}
    </Stack>
  );
};

export default InsightsTab;
