import { draftStatus } from '../State/DraftState';

export const DrafTableTabs = [
  { label: 'All', value: 'all' },
  { label: 'Assigned To Me', value: 'my_assigned_to_me' },
  { label: 'My Template Tasks', value: 'my_template_tasks' },
  { label: 'My Checklist Tasks', value: 'my_checklist_tasks' },
  { label: 'My Approval Tasks', value: 'my_approval_tasks' },
  { label: 'My Signature Tasks', value: 'my_signature_tasks' },
];

export const myDraftTabs = [
  'my_drafts',
  'my_drafts_under_collaboration',
  'my_approval_pending',
  'my_standstill_draft',
  'my_signed_draft',
  'my_signature_pending',
];

export const allDraftsTabs = [
  'total_drafts',
  'drafts_under_collaboration',
  'approval_pending',
  'standstill_draft',
  'signed_draft',
  'signature_pending',
];

export const myStatisticTabs = [
  {
    label: 'Total',
    tooltip: 'Total',
    value: 'my_drafts',
    count: 0,
    apiKey: 'total_my_documents',
  },
  {
    label: 'Collaborating',
    tooltip: 'Collaborating',
    value: 'my_drafts_under_collaboration',
    count: 0,
    apiKey: 'my_drafts_under_collaboration',
  },
  {
    label: 'Appr. Pending',
    tooltip: 'Approval Pending',
    value: 'my_approval_pending',
    count: 0,
    apiKey: 'my_approval_pending',
  },
  {
    label: 'Sign. Pending',
    tooltip: 'Signature Pending',
    value: 'my_signature_pending',
    count: 0,
    apiKey: 'my_signature_pending',
  },
  {
    label: 'Signed',
    tooltip: 'Signed',
    value: 'my_signed_draft',
    count: 0,
    apiKey: 'my_signed_draft',
  },
  {
    label: 'Stand Still',
    tooltip: 'Stand Still',
    value: 'my_standstill_draft',
    count: 0,
    apiKey: 'my_standstill_draft',
  },
];

export const statisticTabs = [
  {
    label: 'Total',
    tooltip: 'Total',
    value: 'total',
    count: 0,
    apiKey: 'total_documents',
  },
  {
    label: 'Collaborating',
    tooltip: 'Collaborating',
    value: 'drafts_under_collaboration',
    count: 0,
    apiKey: 'drafts_under_collaboration',
  },
  {
    label: 'Appr. Pending',
    tooltip: 'Approval Pending',
    value: 'approval_pending',
    count: 0,
    apiKey: 'approval_pending',
  },
  {
    label: 'Sign. Pending',
    tooltip: 'Signature Pending',
    value: 'signature_pending',
    count: 0,
    apiKey: 'signature_pending',
  },
  {
    label: 'Signed',
    tooltip: 'Signed',
    value: 'signed_draft',
    count: 0,
    apiKey: 'signed_draft',
  },
  {
    label: 'Stand Still',
    tooltip: 'Stand Still',
    value: 'standstill_draft',
    count: 0,
    apiKey: 'standstill_draft',
  },
];

export const version0Tabs = [
  {
    label: 'Checklist',
    value: 'checklist',
  },
  // {
  //   label: "Applied Policies",
  //   value: "applied_policies",
  //   notIn: 1,
  // },
  {
    label: 'Collaborate',
    value: 'collaborate',
  },
  // {
  //   label: "Access",
  //   value: "access",
  // },
];

export const version1Tabs = [
  {
    label: 'Collaborate',
    value: 'collaborate',
  },
  {
    label: 'Checklist',
    value: 'checklist',
  },
  // {
  //   label: "Access",
  //   value: "access",
  // },
];

export const dropNotShowingStatus = [
  draftStatus?.Automatically_Dropped,
  draftStatus?.COMMITTED_FOR_SIGNATURE,
  draftStatus?.SIGNATURE_ABORTED,
  draftStatus?.SIGNATURE_PENDING,
  draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY,
];

export const restrictionStatus = [
  draftStatus?.Automatically_Dropped,
  draftStatus?.Draft_Dropped,
];
