import React, { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from '../../../UniversalComponents/Footer/footer';
import Menu from '../../../UniversalComponents/Menu/Container/MenuCon';
import MobileMenu from '../../../UniversalComponents/MobileMenu/Container/mobileMenuCon';
import SideNavbar from '../../../UniversalComponents/SideNavbar/Container/sideNavBarCon';

const Layout = () => {
  const [isMobile] = useState(window.screen.width < 600);
  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {isMobile ? <MobileMenu /> : <Menu />}
      <Stack direction="row" sx={{ width: '100%', flex: 1 }}>
        {pathname !== '/reminders' && <SideNavbar />}
        <Box
          sx={{
            flex: 1,
            padding: '32px 24px',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <main>
            <Outlet />
          </main>
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default Layout;
