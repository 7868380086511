import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import NotificationDropDownBtn from './NotificationDropDownBtn';
import { timeSince } from './NotificationUtils';
import { useUserData } from '../../../App/Component/UserDataProvider';
import DrawerComponent from '../../../RiverusUI/Components/DrawerComponent';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import {
  fetchDontRemindAgain,
  fetchNotifications,
  fetchSnoozeNotification,
} from '../../../Services/Notification';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  updatedNotification: any[];
  setCurrentNotification: Dispatch<SetStateAction<any>>;
}

const notificationType = [
  'Task',
  'Approver',
  'Collaborator',
  'Signatory',
  'Draft_Uploaded',
  'Signature_Pending',
];

const NotificationDrawer: React.FC<IProps> = (props) => {
  const { open, onClose, updatedNotification, setCurrentNotification } = props;
  const queryClient = useQueryClient();
  const { user_id } = useUserData();
  const { control, watch } = useForm();

  const unreadNotification = watch('unread') || '';

  const [allNotifications, setAllNotifications] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(1);
  const [webSocketNotification, setWebSocketNotification] = useState<any[]>([]);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (unreadNotification) {
      queryClient.removeQueries({ queryKey: ['get_all_notifications'] });
      setAllNotifications([]);
      setWebSocketNotification([]);
    }
  }, [unreadNotification]);

  const {
    data: notifications,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['get_all_notifications', user_id, unreadNotification],
    queryFn: async ({ pageParam = 1 }) => {
      let params = `?page=${pageParam}`;
      if (unreadNotification) {
        params = `${params}&is_read=false`;
      }
      const response: any = await fetchNotifications(params);
      setCurrentNotification(response);
      const pageCount = Math.ceil(response?.count / 20);
      setTotalPageCount(pageCount);
      setPageNumber(pageParam + 1);
      return { notifications: response?.results, nextPage: pageParam + 1 };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (pageNumber > totalPageCount) {
        return;
      } else {
        return lastPage?.nextPage;
      }
    },
  });

  const sendDataToWebSocket = (id: string) => {
    // WebSocket connection
    const socket = new WebSocket(
      process.env.REACT_APP_RIVERUS_CLM_WS_API +
        `ws/notification/?token=${user_id}`
    );

    // Data to send
    const data = {
      event: 'notification_read',
      notification_id: id,
    };

    // Send data when the connection is established
    socket.onopen = () => {
      socket.send(JSON.stringify(data));
    };

    // Handle received messages
    socket.onmessage = () => {
      queryClient.invalidateQueries({
        queryKey: ['get_all_notifications', user_id, unreadNotification],
      });
    };

    // Handle errors
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  };

  useEffect(() => {
    const newWebSocketNotifications = updatedNotification?.filter(
      (notification: any) => {
        return !allNotifications?.find(
          (existingNotification: any) =>
            existingNotification?.id === notification?.notification_id
        );
      }
    );
    setWebSocketNotification(newWebSocketNotifications?.reverse());
  }, [updatedNotification]);

  useEffect(() => {
    if (notifications?.pages?.length) {
      notifications?.pages.forEach((notificationItem) => {
        const newNotifications = notificationItem?.notifications || [];
        if (
          updatedNotification?.length &&
          notificationItem?.nextPage === 2 &&
          !unreadNotification
        ) {
          const newWebSocketNotifications = updatedNotification?.filter(
            (notification: any) => {
              return !newNotifications?.find(
                (existingNotification: any) =>
                  existingNotification?.id === notification?.notification_id
              );
            }
          );
          setWebSocketNotification(newWebSocketNotifications?.reverse());
        }
        if (notificationItem?.nextPage === 2) {
          setAllNotifications(newNotifications);
        } else {
          setAllNotifications((prev) => [...prev, ...newNotifications]);
        }
      });
    }
  }, [notifications?.pages, unreadNotification]);

  const parseMessage = (message: string) => {
    const regex = /(.*)<a href='(.*?)'>(.*?)<\/a>(.*)/;
    const match = message.match(regex);

    if (match) {
      const beforeLink = match[1]?.trim();
      const href = match[2];
      const linkText = match[3];
      const afterLink = match[4]?.trim();

      return {
        beforeLink,
        href,
        linkText,
        afterLink,
      };
    }

    const beforeLink = message;

    return { beforeLink };
  };

  const { mutate: snooze } = useMutation({
    mutationKey: ['snooze_notification'],
    mutationFn: fetchSnoozeNotification,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['get_all_notifications'],
      });
      setAllNotifications((prev) =>
        prev.filter((notification) => notification.id !== variables)
      );
    },
  });

  const { mutate: dontRemind } = useMutation({
    mutationKey: ["don't_remind_again_notification"],
    mutationFn: fetchDontRemindAgain,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['get_all_notifications'],
      });
      setAllNotifications((prev) =>
        prev.filter((notification) => notification.id !== variables)
      );
    },
  });

  const handleScrollToBottom = () => {
    if (hasNextPage && pageNumber <= totalPageCount) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && pageNumber <= totalPageCount) {
        handleScrollToBottom();
      }
    });

    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current);
    }

    return () => {
      if (observerRef.current && sentinelRef.current) {
        observerRef.current.unobserve(sentinelRef.current);
      }
    };
  }, [hasNextPage, pageNumber, totalPageCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 30000); // Update every 30 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollTop } = scrollContainerRef.current;
      setShowScrollToTop(scrollTop > 200);
    }
  };

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleClose = () => {
    queryClient.removeQueries({ queryKey: ['get_all_notifications'] });
    setAllNotifications([]);
    setWebSocketNotification([]);
    setPageNumber(1);
    onClose();
  };

  return (
    <DrawerComponent
      open={open}
      onClose={handleClose}
      drawerHeader="Notifications"
      renderAction={
        <NotificationDropDownBtn
          notifications_ids={[...allNotifications, ...webSocketNotification]}
          control={control}
          unreadNotification={unreadNotification}
        />
      }
      sx={{
        '& .MuiDrawer-paper': {
          width: '550px',
        },
      }}
    >
      {isFetching &&
        !allNotifications?.length &&
        !webSocketNotification?.length && (
          <Box px={2}>
            <ListSkeleton />
          </Box>
        )}

      <Stack
        direction="column"
        spacing={2}
        p={2}
        ref={scrollContainerRef}
        onScroll={handleScroll}
        sx={{ overflowY: 'auto' }}
      >
        {webSocketNotification?.map((notification: any, index: number) => (
          <Stack
            key={`websocket-notification-${index}`}
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" spacing={2} width="100%">
              <TextSnippetOutlinedIcon sx={{ color: '#88305F' }} />
              <Stack direction="column" spacing={1} width="100%">
                {notification?.message && (
                  <>
                    {parseMessage(notification?.message) && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#000000',
                          cursor: notification?.is_read ? 'default' : 'pointer',
                        }}
                        onClick={() => {
                          if (!notification?.is_read) {
                            sendDataToWebSocket(notification?.notification_id);
                          }
                        }}
                      >
                        {parseMessage(notification?.message)?.beforeLink}{' '}
                        <Link
                          href={notification?.link}
                          target="_blank"
                          variant="body1"
                          color="inherit"
                        >
                          {parseMessage(notification?.message)?.linkText}
                        </Link>{' '}
                        {parseMessage(notification?.message)?.afterLink}
                      </Typography>
                    )}
                  </>
                )}
                <Typography variant="caption" sx={{ color: '#4A4458' }}>
                  {timeSince(notification?.created, currentTime)}
                </Typography>
                {notificationType?.includes(
                  notification?.notification_type
                ) && (
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      variant="text"
                      startIcon={<AccessTimeIcon />}
                      sx={{ padding: 0 }}
                      onClick={() => snooze(notification?.notification_id)}
                    >
                      Snooze for 1 hour
                    </Button>

                    <Button
                      variant="text"
                      startIcon={<HighlightOffRoundedIcon />}
                      sx={{ padding: 0 }}
                      onClick={() => dontRemind(notification?.notification_id)}
                    >
                      Don’t remind again
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {!notification?.is_read && (
              <FiberManualRecordIcon
                sx={{ color: '#88305F', fontSize: '12px', mt: '5px' }}
              />
            )}
          </Stack>
        ))}
        {allNotifications?.map((notification: any, index: number) => (
          <Stack
            key={`notification-${index}`}
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" spacing={2} width="100%">
              <TextSnippetOutlinedIcon sx={{ color: '#88305F' }} />
              <Stack direction="column" spacing={1} width="100%">
                {notification?.message && (
                  <>
                    {parseMessage(notification?.message) && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#000000',
                          cursor: notification?.is_read ? 'default' : 'pointer',
                        }}
                        onClick={() => {
                          if (!notification?.is_read) {
                            sendDataToWebSocket(notification?.id);
                          }
                        }}
                      >
                        {parseMessage(notification?.message)?.beforeLink}{' '}
                        <Link
                          href={notification?.link}
                          target="_blank"
                          variant="body1"
                          color="inherit"
                        >
                          {parseMessage(notification?.message)?.linkText}
                        </Link>{' '}
                        {parseMessage(notification?.message)?.afterLink}
                      </Typography>
                    )}
                  </>
                )}
                <Typography variant="caption" sx={{ color: '#4A4458' }}>
                  {timeSince(notification?.created, currentTime)}
                </Typography>
                {notificationType?.includes(
                  notification?.notification_type
                ) && (
                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      variant="text"
                      startIcon={<AccessTimeIcon />}
                      sx={{ padding: 0 }}
                      onClick={() => snooze(notification?.id)}
                    >
                      Snooze for 1 hour
                    </Button>

                    <Button
                      variant="text"
                      startIcon={<HighlightOffRoundedIcon />}
                      sx={{ padding: 0 }}
                      onClick={() => dontRemind(notification?.id)}
                    >
                      Don’t remind again
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
            {!notification?.is_read && (
              <FiberManualRecordIcon
                sx={{ color: '#88305F', fontSize: '12px', mt: '5px' }}
              />
            )}
          </Stack>
        ))}
        {!isFetching &&
          allNotifications?.length === 0 &&
          webSocketNotification?.length === 0 && (
            <Typography>No notification found</Typography>
          )}
        {totalPageCount >= pageNumber && (
          <div ref={sentinelRef} style={{ height: '20px' }} />
        )}
        {isFetching && pageNumber !== 1 && (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>

      {showScrollToTop && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: '3%',
            right: '3%',
            backgroundColor: 'rgba(109, 38, 76, 0.8)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(41, 14, 28, 0.8)',
            },
          }}
        >
          <ArrowUpwardIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      )}
    </DrawerComponent>
  );
};

export default NotificationDrawer;
