import React from 'react';

import { Chip, ChipProps, SvgIconProps } from '@mui/material';

interface IProps extends ChipProps {
  icon?: React.ReactElement<SvgIconProps>;
  label: string;
  chipColor?: string;
  chipBackground?: string;
}

const CustomChip: React.FC<IProps> = ({
  icon,
  label,
  chipColor,
  chipBackground,
  sx,
  ...props
}) => {
  return (
    <Chip
      sx={{
        background: chipBackground || '#FFECF1',
        padding: '15px 10px',
        borderRadius: '5px',
        color: chipColor || '#6D264C',
        ...sx,
      }}
      icon={icon}
      label={label}
      {...props}
    />
  );
};

export default CustomChip;
