import React, { useEffect } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { addProject, fetchProjects } from '../../../Services/DocumentLibrary';
import { bulkUpdateProjects } from '../../../Services/Draft';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  selectedIds: GridRowId[];
  replace: boolean;
  title: string;
  data: any;
}

const AddEditProjects: React.FC<Props> = ({
  open,
  onClose,
  selectedIds,
  replace,
  title,
  data,
}) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const queryClient = useQueryClient();

  const handleClose = () => {
    reset();
    onClose();
  };

  const { data: projectData, isLoading: projectLoading } = useQuery({
    queryKey: ['project_data'],
    queryFn: async () => await fetchProjects(),
    select: (response: any) =>
      response.results.filter((data: any) => data.name !== ''),
  });

  useEffect(() => {
    if (data && selectedIds?.length === 1) {
      const selectedProject = data?.find(
        (item: any) => item?.id === selectedIds[0]
      )?.projects?.[0];
      if (selectedProject) {
        setValue('projects', selectedProject?.id);
      }
    }
  }, [data, selectedIds, setValue]);

  const { mutate: editReplaceProjectMutation, isPending: editReplaceLoading } =
    useMutation({
      mutationKey: ['edit_replace_ids'],
      mutationFn: async (payload: any) => bulkUpdateProjects(payload),
      onSuccess: () => {
        enqueueSnackbar('Projects updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        handleClose();
        queryClient.invalidateQueries({
          queryKey: ['drafts'],
        });
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.projects?.[0];
        enqueueSnackbar(`${responseData || 'Failed to update projects!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: addProjectMutation } = useMutation({
    mutationKey: ['add-project'],
    mutationFn: addProject,
    onSuccess: () => {
      enqueueSnackbar('Project added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({ queryKey: ['project_data'] });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create project'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = (data: any) => {
    const payload = {
      ids: selectedIds,
      projects: data?.projects,
    };
    editReplaceProjectMutation(payload);
  };

  return (
    <CustomModal title={title} open={open} handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} width="500px" pt="20px">
          {replace && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <ErrorOutlineIcon style={{ color: '#938F99' }} />
              <Typography color="#938F99">
                All existing project for the selected drafts will be replaced.
              </Typography>
            </Stack>
          )}
          <RISelectComponent
            name="projects"
            control={control}
            label="Project *"
            options={sortListAlphabetical(projectData)}
            loading={projectLoading}
            valueKey="id"
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                {...props}
                icon={
                  <WorkOutlineIcon
                    style={{
                      color: '#6D264C',
                    }}
                  />
                }
                label={value?.name}
              />
            )}
            canCreateNew
            addNewValue={(value) =>
              addProjectMutation({ name: value, displayName: value })
            }
          />
        </Stack>
        <Stack
          direction="row"
          marginTop={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row">
            <LoadingButton
              loading={editReplaceLoading}
              variant="contained"
              type="submit"
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default AddEditProjects;
