import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import CustomModal from '../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../RiverusUI/Components/SelectComponent';

interface IProp {
  open: boolean;
  onClose: VoidFunction;
  title: string;
  setDateRangeParams: Dispatch<SetStateAction<any>>;
  dateRange: any;
  type?: any;
}

const DateRangeModel: React.FC<IProp> = ({
  open,
  onClose,
  title,
  setDateRangeParams,
  dateRange,
  type,
}) => {
  const [startEndDate, setStartEndDate] = useState<any>({
    start: null,
    end: null,
  });

  const methods = useForm();
  const { control, setValue, watch } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const selectedType = watch('filter_type');

  useEffect(() => {
    if (dateRange) {
      setValue('filter_type', dateRange?.type || '');
    }
  }, [dateRange, setValue]);

  const onSubmit = () => {
    const start_date = dayjs(startEndDate?.start).isValid()
      ? dayjs(startEndDate?.start).format('YYYY-MM-DD')
      : null;
    const end_date = dayjs(startEndDate?.end).isValid()
      ? dayjs(startEndDate?.end).format('YYYY-MM-DD')
      : null;
    if (start_date && end_date && (type !== 'umbrella' || selectedType)) {
      setDateRangeParams({
        type: selectedType && type === 'umbrella' ? selectedType : undefined,
        start: start_date,
        end: end_date,
      });
      onClose();
    } else {
      const msg = `Please select ${!selectedType && type === 'umbrella' ? 'filter type' : !startEndDate?.start ? 'start date!' : 'end date'}`;
      enqueueSnackbar(msg, {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (dateRange?.start && dateRange?.end) {
      setStartEndDate({
        start: dayjs(dateRange?.start),
        end: dayjs(dateRange?.end),
      });
    }
  }, [dateRange?.start, dateRange?.end]);

  const customOptions = [
    { name: 'Uploaded On', value: 'created' },
    { name: 'Start Date', value: 'start' },
    { name: 'End Date', value: 'end' },
  ];

  return (
    <CustomModal title={title} open={open} handleClose={onClose}>
      <Stack mt={3} spacing={2}>
        {type === 'umbrella' && (
          <RISelectComponent
            name="filter_type"
            control={control}
            label="Filter Type"
            labelKey="name"
            options={customOptions}
            valueKey="value"
            required
          />
        )}
        <Stack direction="row" alignItems="center" gap={2}>
          <DatePicker
            label="From"
            format="DD/MM/YYYY"
            value={startEndDate?.start || null}
            onChange={(start: any) => {
              if (start && dayjs(start).isAfter(dayjs())) {
                enqueueSnackbar('Start date cannot be in the future!', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });
                setStartEndDate({ ...startEndDate, start: null });
              } else {
                setStartEndDate({ ...startEndDate, start });
              }
            }}
            disableFuture={true}
          />

          <Typography> - </Typography>

          <DatePicker
            label="To"
            format="DD/MM/YYYY"
            value={startEndDate?.end || null}
            onChange={(end: any) => {
              if (
                end &&
                startEndDate.start &&
                dayjs(end).isBefore(dayjs(startEndDate.start))
              ) {
                enqueueSnackbar('End date cannot be before start date!', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                });
                setStartEndDate({ ...startEndDate, end: null });
              } else {
                setStartEndDate({ ...startEndDate, end });
              }
            }}
            disabled={!startEndDate.start} // Disable until start date is selected
            minDate={startEndDate.start || undefined} // Ensure end date is after start date
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row">
            <LoadingButton onClick={onSubmit} variant="contained">
              filter
            </LoadingButton>

            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
          <Button
            variant="text"
            onClick={() => {
              setStartEndDate({
                start: null,
                end: null,
              });
              setDateRangeParams({
                type: null,
                start: null,
                end: null,
              });
            }}
          >
            Clear Filter
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default DateRangeModel;
