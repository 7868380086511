import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Add } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SortIcon from '@mui/icons-material/Sort';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';

import AddSectionModal from './AddSectionModal';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import { reorderSections } from '../../../Services/dictionary';

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  padding: '8px 15px',
  color: '#1D1B20',
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  ...draggableStyle,
});

interface IProps {
  setActiveSection: Dispatch<SetStateAction<any>>;
  setActiveField: Dispatch<SetStateAction<any>>;
  activeSection?: any;
  sections?: any;
  activeField?: any;
  showInsights?: boolean;
  setShowInsights: Dispatch<SetStateAction<boolean>>;
}

const SectionsList: FC<IProps> = (props) => {
  const {
    activeSection,
    sections,
    setActiveField,
    setActiveSection,
    activeField,
    showInsights,
    setShowInsights,
  } = props;

  const { id } = useParams<any>();
  const [isEdit, setEdit] = useState<any>(false);
  const [openAddSection, setOpenAddSection] = useState<boolean>(false);
  const [sectionsData, setSectionsData] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: reorderSectionsMutation } = useMutation({
    mutationKey: ['reorder_sections'],
    mutationFn: reorderSections,
    onSuccess: () => {
      enqueueSnackbar('Sections reordered successfully!', {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['dictionary-fields'] });
      setEdit(false);
    },
  });

  useEffect(() => {
    setSectionsData(sections?.data || []);
  }, [sections]);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setActiveSection(isExpanded ? panel : '');
      setShowInsights(true);
      setActiveField({});
    };

  useEffect(() => {
    if (activeSection.section_id) {
      const updatedSection = sectionsData.filter(
        (data: any) => data.section_id === activeSection.section_id
      );
      setActiveSection(updatedSection[0]);
    }
  }, [activeSection, sectionsData, setActiveSection]);

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sectionsData,
      result.source.index,
      result.destination.index
    );
    setSectionsData(items);
  };

  const onSaveOrder = useCallback(() => {
    reorderSectionsMutation({
      id: id,
      sorted: sections.sorting,
      body: {
        order_list: sectionsData.map((data: any) => data.section_id),
      },
    });
  }, [reorderSectionsMutation, id, sections, sectionsData]);

  return (
    <Stack width="550px" spacing={1}>
      <Stack
        direction="row"
        style={innerTabStyle}
        justifyContent="space-between"
        alignItems="center"
      >
        Clauses
        {isEdit ? (
          <Button
            variant="text"
            sx={{ padding: 0, margin: 0 }}
            onClick={onSaveOrder}
          >
            Save Order
          </Button>
        ) : (
          <Button
            variant="text"
            sx={{ padding: 0, margin: 0 }}
            startIcon={<SortIcon />}
            onClick={() => {
              setEdit(true);
              setActiveField({});
              setActiveSection('');
            }}
            disabled={!sectionsData?.length}
          >
            Edit Order
          </Button>
        )}
      </Stack>
      <Stack
        style={{ ...innerTabStyle, background: '#88305F0D', height: '100%' }}
        spacing={2}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" isDropDisabled={!isEdit}>
            {(provided: any) => (
              <Stack
                spacing={1}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sectionsData?.map((section: any, index: number) => (
                  <Draggable
                    key={`${section.section_id}-${Date.now()}-${index}`}
                    draggableId={section.section_id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Accordion
                          key={`${section.section_id}-${Date.now()}`}
                          sx={{
                            background: '#88305F24',
                            boxShadow: 'none',
                          }}
                          expanded={
                            activeSection.section_id === section.section_id
                          }
                          onChange={handleAccordionChange(section)}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {section.section_name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack gap={1}>
                              <Stack
                                key={`${section.section_id}-${Date.now()}`}
                                justifyContent="space-between"
                                alignItems="center"
                                direction="row"
                                sx={{
                                  padding: '8px 12px',
                                  background: showInsights
                                    ? '#FFFAFA'
                                    : '#fff7faa8',
                                  border: showInsights
                                    ? '1px solid #88305F'
                                    : 'none',
                                  borderRadius: '12px',
                                  color: '#1D1B20',
                                  fontSize: '16px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setShowInsights(true);
                                  setActiveField({});
                                }}
                              >
                                Insight: {section.section_name}
                                <PlayArrowIcon sx={{ fontSize: '14px' }} />
                              </Stack>
                              {section?.field_data?.map(
                                (field: any, index: number) => (
                                  <Stack
                                    key={`${
                                      field.field_id
                                    }-${Date.now()}-${index}`}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    direction="row"
                                    sx={{
                                      padding: '8px 12px',
                                      background:
                                        activeField?.field_id === field.field_id
                                          ? '#FFFAFA'
                                          : '#fff7faa8',
                                      border:
                                        activeField?.field_id === field.field_id
                                          ? '1px solid #88305F'
                                          : 'none',
                                      borderRadius: '12px',
                                      color: '#1D1B20',
                                      fontSize: '16px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setActiveField(field);
                                      setShowInsights(false);
                                    }}
                                  >
                                    Field: {field.display_name}
                                    <PlayArrowIcon sx={{ fontSize: '14px' }} />
                                  </Stack>
                                )
                              )}
                              <Button
                                variant="text"
                                startIcon={<Add />}
                                sx={{
                                  padding: 0,
                                  margin: '4px 0',
                                  width: 'fit-content',
                                }}
                                onClick={() => {
                                  setActiveField({});
                                  setShowInsights(false);
                                }}
                              >
                                {`Add ${section?.field_data?.length ? 'another' : ''} field`}
                              </Button>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                  </Draggable>
                ))}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          variant="outlined"
          sx={{ borderColor: '#88305F24', borderRadius: '18px' }}
          startIcon={<Add />}
          onClick={() => setOpenAddSection(true)}
        >
          {`Add ${sectionsData?.length ? 'another' : ''} section`}
        </Button>
      </Stack>
      {openAddSection && (
        <AddSectionModal
          open={openAddSection}
          onClose={() => setOpenAddSection(false)}
          id={id as string}
        />
      )}
    </Stack>
  );
};

export default SectionsList;
