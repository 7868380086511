import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Chip, FormControl, IconButton, InputBase, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { sortListAlphabetical } from '../../Draft/Component/Helper';
import { fetchExternalUsersList, getInternalUsers } from '../../Services/Draft';
import RISelectComponent from '../Components/SelectComponent';

interface IProps {
  setIsSearch: Dispatch<SetStateAction<boolean>>;
  onChangeAssignee?: any;
  onChangeRoles?: any;
  isLoading?: boolean;
}

const roles = [
  { name: 'Owner', value: 'owners' },
  { name: 'Collaborator', value: 'collaborators' },
  { name: 'Approver', value: 'approvers' },
  { name: 'Pre Signatory', value: 'pre_signatory' },
  { name: 'Signatory', value: 'signatory' },
  { name: 'Creators', value: 'creators' },
];

const TableAssigneeSelectInput: React.FC<IProps> = ({
  setIsSearch,
  onChangeAssignee,
  onChangeRoles,
  isLoading,
}) => {
  const [userLists, setUserLists] = React.useState<any[]>([]);

  const { control, watch } = useForm();

  const selectedRoleValue = watch('roles') || '';
  const selectedAssigneeValue = watch('assignee') || '';

  useEffect(() => {
    if (selectedRoleValue) {
      onChangeRoles?.(selectedRoleValue);
    }
  }, [selectedRoleValue]);

  useEffect(() => {
    if (selectedAssigneeValue) {
      onChangeAssignee?.(selectedAssigneeValue);
    }
  }, [selectedAssigneeValue]);

  const { data: externalUsersData } = useQuery({
    queryKey: ['external_users_list'],
    queryFn: fetchExternalUsersList,
    select: (response: any) => {
      const groups = response.results.map((data: any) => {
        const name =
          data?.first_name === data?.last_name
            ? data?.first_name
            : `${data.first_name} ${data?.last_name}`;
        return {
          ...data,
          name: `${name} - ${data.email}`,
        };
      });
      return sortListAlphabetical(groups);
    },
  });

  const { data: internalUsersData } = useQuery({
    queryKey: ['internal_users_list'],
    queryFn: getInternalUsers,
    select: (response: any) => {
      const groups = response.results.map((data: any) => {
        const name =
          data?.first_name === data?.last_name
            ? data?.first_name
            : `${data.first_name} ${data?.last_name}`;
        return {
          ...data,
          name: `${name} - ${data.email}`,
        };
      });
      return sortListAlphabetical(groups);
    },
  });

  const filteredExternalUsers = useMemo(() => {
    return externalUsersData?.filter((user: any) => user?.is_active);
  }, [externalUsersData]);

  useEffect(() => {
    setUserLists([
      ...(internalUsersData || []),
      ...(filteredExternalUsers || []),
    ]);
  }, [filteredExternalUsers, internalUsersData]);

  return (
    <FormControl sx={{ m: 0, width: 600 }}>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <RISelectComponent
          name="roles"
          control={control}
          isMultiselect
          valueKey="value"
          labelKey="name"
          options={roles || []}
          renderCustomComponent={(value: any, props) => (
            <Chip {...props} label={value?.['name'] || value} />
          )}
          disableCloseOnSelect={false}
          isFilterCustomComponent={true}
          CustomInput={(params) => (
            <InputBase
              placeholder="Select Role"
              sx={{
                width: '200px',
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 0.5,
                height: '55px',
                alignItems: 'center',
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                pointerEvents: isLoading ? 'none' : '',
                opacity: isLoading ? 0.6 : '',
              }}
              {...params.InputProps}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <RISelectComponent
          name="assignee"
          control={control}
          isMultiselect
          valueKey="id"
          labelKey="name"
          isGroupByOptions={true}
          options={userLists || []}
          renderCustomComponent={(value: any, props) => (
            <Chip {...props} label={value?.['name'] || value} />
          )}
          disableCloseOnSelect={false}
          isFilterCustomComponent={true}
          CustomInput={(params) => (
            <InputBase
              placeholder="Select Assignee"
              sx={{
                width: '300px',
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 0.5,
                height: '55px',
                alignItems: 'center',
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                pointerEvents: isLoading ? 'none' : '',
                opacity: isLoading ? 0.6 : '',
              }}
              {...params.InputProps}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />

        {!selectedRoleValue?.length && !selectedAssigneeValue?.length && (
          <IconButton
            type="button"
            sx={{ p: 0, height: '60px' }}
            aria-label="search"
            onClick={() => {
              setIsSearch(false);
              setUserLists([]);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </FormControl>
  );
};

export default TableAssigneeSelectInput;
