import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { GroupOutlined, PersonOffOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import DropdownButton from './DropdownButton';
import { draftStatus } from '../../Draft/State/DraftState';
import { editDraftData } from '../../Services/Draft';
import ControlledTextField from '../Components/ControlledTextField';
import ReusableConfirmationModal from '../Components/ReusableConfirmationModal';

interface MenuOption {
  value: string;
  label: string;
  startIcon?: any;
  isLocked?: boolean;
}

const collaborateMenu: MenuOption[] = [
  {
    value: 'collaborating',
    label: 'Collaborating',
    startIcon: <GroupOutlined />,
  },
  {
    value: 'editing',
    label: 'Editing',
    startIcon: <PersonOffOutlined />,
    isLocked: false,
  },
];

interface Props {
  draftData?: any;
  canDownload?: boolean;
  collaborating?: boolean;
  goBackUrl?: string;
  renderAction: () => React.ReactNode;
  version: number;
  name?: string;
  setCanEdit?: Dispatch<SetStateAction<boolean>>;
  handleDownload?: VoidFunction;
  collaboratorDisabled?: boolean;
  canEdit?: boolean;
  isLocked?: boolean;
  showLockedModal?: VoidFunction;
  isLoadingFetchingData?: any;
  handleBoostWithAiClick?: any;
  fetchingBoostWithAi?: any;
  setOpenConfirmationModal?: any;
  openConfirmationModal?: any;
  tabs?: any;
  selectedOption?: any;
  setSelectedOption?: any;
  isBoostWithAiSelectedAndInProgress?: boolean;
  isBoostWithKdpSelectedAndInProgress?: boolean;
}

const DraftHeader: React.FC<Props> = ({
  draftData,
  collaborating = false,
  canDownload = false,
  renderAction,
  version,
  goBackUrl,
  name,
  setCanEdit,
  handleDownload,
  collaboratorDisabled = false,
  canEdit = false,
  isLocked = false,
  showLockedModal,
  isLoadingFetchingData,
  handleBoostWithAiClick,
  fetchingBoostWithAi,
  setOpenConfirmationModal,
  openConfirmationModal,
  tabs,
  selectedOption,
  setSelectedOption,
  isBoostWithAiSelectedAndInProgress,
  isBoostWithKdpSelectedAndInProgress,
}) => {
  const [collaboratingValue, setCollaboratingValue] = useState<string>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateDraftData } = useMutation({
    mutationFn: editDraftData,
    onSuccess: () => {
      enqueueSnackbar('Draft name updated Successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_draft_by_id', draftData?.id],
      });
      onCancel();
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.non_field_errors;
      enqueueSnackbar(`${responseData || 'Failed to update draft name!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleConfirm = () => {
    handleBoostWithAiClick();
  };

  const handleToggleBtns = (newOption: any) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      setOpenConfirmationModal(true);
    }
  };

  React.useEffect(() => {
    if (collaboratingValue === 'Editing') {
      setCanEdit?.(true);
    } else {
      setCanEdit?.(false);
    }
  }, [collaboratingValue, isLocked, setCanEdit, showLockedModal]);

  useEffect(() => {
    if (isLocked) {
      collaborateMenu.map((data) => {
        if (data.value === 'editing') {
          data.isLocked = true;
        }
      });
    }
  }, [isLocked]);

  useEffect(() => {
    if (draftData?.extraction_type === 'kdp') {
      setSelectedOption('boost-with-kdp');
    } else if (draftData?.extraction_type === 'ai') {
      setSelectedOption('boost-with-ai');
    }
  }, [draftData?.extraction_type]);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    resetField,
    setValue,
  } = useForm();

  const onCancel = () => {
    resetField('contractName');
    setIsEdit(false);
  };

  useEffect(() => {
    if (name && isEdit) {
      setValue('contractName', name);
    }
  }, [name, isEdit]);

  const onSubmit = (data: any) => {
    const payload = {
      id: draftData?.id,
      body: data,
    };
    if (isDirty) updateDraftData(payload);
  };

  return (
    <Box>
      <Toolbar className="header-wrapper">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            {goBackUrl && (
              <Link
                href={goBackUrl}
                color="riPrimary.500"
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowBackIcon fontSize="small" sx={{ marginRight: '2px' }} />
                Close
              </Link>
            )}
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="center"
            className="title-wrapper"
          >
            {name &&
              draftData?.id &&
              !isEdit &&
              (draftData?.status ===
              draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY ? (
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: '600',
                    marginRight: '1rem',
                    color: '#6D264C',
                  }}
                >
                  {name}
                </Typography>
              ) : (
                <Button
                  variant="text"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setIsEdit(true)}
                  endIcon={<EditOutlinedIcon />}
                >
                  {name}
                </Button>
              ))}
            {isEdit && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ControlledTextField
                    name="contractName"
                    control={control}
                    label="Draft Name"
                    fullWidth
                  />
                  <IconButton onClick={onCancel}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Stack>
              </form>
            )}
            <Typography
              marginRight={2}
              fontSize="14px"
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {draftData?.id && name ? '' : name}{' '}
              {version > -1 && `(V${version})`}
            </Typography>
            {collaborating && (
              <>
                <Divider orientation="vertical" flexItem />
                <Box marginLeft={0.5}>
                  <DropdownButton
                    changeButtonText
                    options={collaborateMenu}
                    setValue={(value: string) => {
                      if (isLocked && value === 'Editing') {
                        showLockedModal?.();
                        setCollaboratingValue('Collaborating');
                      } else {
                        setCollaboratingValue(value);
                      }
                    }}
                    disabled={collaboratorDisabled}
                    buttonName={canEdit ? 'Editing' : 'Collaborating'}
                  />
                </Box>
              </>
            )}
            {tabs?.some((tab: any) => tab?.value === 'insights') && (
              <ToggleButtonGroup
                value={selectedOption}
                exclusive
                onChange={handleToggleBtns}
                disabled={isLoadingFetchingData}
                sx={{
                  border: '2px solid #6D264C',
                  borderRadius: '20px',
                  width: 'fit-content',
                  height: '1.5rem',
                  whiteSpace: 'nowrap',
                  padding: '2px',
                  background: '#f1eaed',
                }}
              >
                <Tooltip title="Boost With Regular">
                  <ToggleButton
                    value="boost-with-kdp"
                    disabled={
                      draftData?.extraction_type === 'kdp' ||
                      isBoostWithAiSelectedAndInProgress
                    }
                    sx={{
                      borderRadius: '16px !important',
                      border: 'unset',
                      color:
                        draftData?.extraction_type === 'kdp'
                          ? '#fff !important'
                          : '#6D264C !important',
                      backgroundColor:
                        draftData?.extraction_type === 'kdp'
                          ? '#6D264C !important'
                          : '',
                      height: '1.5rem !important',
                      fontSize: '12px !important',
                    }}
                  >
                    Regular
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Boost With  Ultra AI">
                  <ToggleButton
                    value="boost-with-ai"
                    disabled={
                      draftData?.extraction_type === 'ai' ||
                      isBoostWithKdpSelectedAndInProgress
                    }
                    sx={{
                      borderRadius: '16px !important',
                      border: 'unset',
                      color:
                        draftData?.extraction_type === 'ai'
                          ? '#fff !important'
                          : '#6D264C !important',
                      backgroundColor:
                        draftData?.extraction_type === 'ai'
                          ? '#6D264C !important'
                          : '',
                      height: '1.5rem !important',
                      fontSize: '12px !important',
                    }}
                  >
                    Ultra AI
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {canDownload && (
              <Button
                onClick={handleDownload}
                variant="text"
                startIcon={<SaveAltIcon />}
              >
                Download
              </Button>
            )}
            {renderAction()}
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
      {openConfirmationModal && (
        <ReusableConfirmationModal
          open={openConfirmationModal}
          onClose={handleClose}
          title={`Boost With ${draftData?.extraction_type === 'kdp' ? 'Ultra AI' : 'KDP'}`}
          cancelBtnText="No, Go Back"
          confirmBtnText="Confirm"
          onConfirm={handleConfirm}
          buttonLoading={fetchingBoostWithAi}
        >
          <Stack spacing={2}>
            <Typography>
              Are you sure you want to Boost with
              {draftData?.extraction_type === 'kdp' ? ' Ultra AI' : ' KDP'}
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Box>
  );
};

export default DraftHeader;
