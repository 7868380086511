import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';

import AddCustomIssue from './AddCustomIssue';
import CustomDeviationCard from './CustomDeviationCard';

interface Props {
  instance: WebViewerInstance | null;
  draftData: any;
  userIsOwner: boolean;
  customDeviationList: any[];
  combinedApproversIds: any[];
  deviationType: string;
  isDraftDropped?: boolean;
  draftGroups: any[];
  deviationsList: any[];
}

const CustomDeviation: React.FC<Props> = ({
  instance,
  draftData,
  userIsOwner,
  customDeviationList,
  combinedApproversIds,
  deviationType,
  isDraftDropped,
  draftGroups,
  deviationsList,
}) => {
  return (
    <Stack spacing={3}>
      <Stack direction="row" color="grey" alignItems="start" spacing={1}>
        <InfoOutlinedIcon />
        <Typography>
          Please track escalated issues under &quot;Collaborate&quot; tab
        </Typography>
      </Stack>
      <AddCustomIssue
        draftData={draftData}
        instance={instance}
        combinedApproversIds={combinedApproversIds}
        isDraftDropped={isDraftDropped}
        draftGroups={draftGroups}
      />
      {/* {customDeviationList?.map((deviation: any, index: number) => (
        <CustomDeviationCard
          key={index}
          cardData={deviation}
          instance={instance}
          userIsOwner={userIsOwner}
          deviationType={deviationType}
          draftData={draftData}
          isDraftDropped={isDraftDropped}
          customDeviationList={customDeviationList}
          deviationsList={deviationsList}
        />
      ))} */}
    </Stack>
  );
};

export default CustomDeviation;
