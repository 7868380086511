export const contractTabLabel: any = {
  contracts_count: 'All Contracts',
  active: 'Active',
  expiring: 'Exp. this Month',
  expiring_this_quarter: 'Exp. this Quarter',
  expiring_next_quarter: 'Exp. next Quarter',
  expired: 'Expired',
  // total_contracts_size: 'Used Space',
  total_reminders: 'With Reminders',
  terminated: 'Terminated',
  under_renewal: 'Under Renewal',
};

export const contractTabTooltip: any = {
  contracts_count: 'All Contracts',
  active: 'Active',
  expiring: 'Expiring this Month',
  expiring_this_quarter: 'Expiring this Quarter',
  expiring_next_quarter: 'Expiring next Quarter',
  expired: 'Expired',
  // total_contracts_size: 'Used Space',
  total_reminders: 'With Reminders',
  terminated: 'Terminated Contracts',
  under_renewal: 'Renewals initiated but not yet executed',
};

export const contractTableTabLabel: any = {
  all: 'All Contracts',
  // active: 'Active Contracts',
  // expired: 'Expired Contracts',
  // terminate: 'Terminated Contracts',
  my_contracts: 'My Contracts',
  all_under_renewal: 'Under Renewal',
};

export const statusFilterList = [
  { value: 'not started', name: 'Not Started' },
  { value: 'processing', name: 'Processing' },
  { value: 'done', name: 'Done' },
];
