import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import ActionButton from './ActionButton';
import RequestApproval from './RequestApproval';
import CreateApprovalComponent from '../../../Approvals/Component/CreateApprovalForm/CreateApprovalComponent';
import { updateDeviations } from '../../../Services/Draft';
import { droppedMsg, handleSeeMoreAndLess } from '../Helper';
import { allInsightsList } from '../InsightsTab/InsightsTab';

interface Props {
  cardData: any;
  instance: any;
  userIsOwner: boolean;
  combinedApproversIds: any[];
  isDraftDropped?: boolean;
}

const status: any = {
  deviant: '#F2B8B5',
  compliant: '#CFE7BC',
  unrecognized: '#FEDDB5',
};

const descriptionHeading: any = {
  deviant: 'The following text conflicts with the above policy',
  compliant: 'The following text is consistent with the above policy',
  unrecognized: 'The applicable text could not be verified',
};

const DeviationCard: React.FC<Props> = ({
  cardData,
  instance,
  userIsOwner,
  combinedApproversIds,
  isDraftDropped,
}) => {
  const [isShowAddMoreText, setIsShowAddMoreText] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openRequestApproval, setOpenRequestApproval] =
    useState<boolean>(false);
  const [fieldValueDataOfKdp, setFieldValueDataOfKdp] = useState<any>({});
  const [openCreateApproval, setOpenCreateApproval] = useState<boolean>(false);
  const [editCopyDetailId, setEditCopyDetailId] = useState<any>();

  const [showFullText, setShowFullText] = useState<any>({
    id: '',
    show: false,
  });

  const queryClient = useQueryClient();

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseRequestApprovalDialog = () => {
    setOpenRequestApproval(false);
  };

  const { mutate: update_deviations } = useMutation({
    mutationKey: ['update_deviation'],
    mutationFn: (payload: any) => {
      return updateDeviations(cardData?.id, payload);
    },
    onSuccess: () => {
      handleCloseDeleteDialog();
      queryClient.invalidateQueries({
        queryKey: ['fetch_Kdp_deviations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_ai_Kdp_deviations'],
      });
      setIsShowAddMoreText(false);
    },
  });

  const handleRemoveDeviationText = (id: string) => {
    if (cardData?.draft_datapoint?.id === id) {
      cardData.draft_datapoint = null;
    } else {
      if (cardData?.manual_datapoint?.length > 0) {
        const filteredData = cardData?.manual_datapoint?.filter(
          (item: any) => item?.id !== id
        );
        cardData.manual_datapoint = filteredData;
      }
      if (cardData?.kdp_datapoint?.length > 0) {
        const filteredData = cardData?.kdp_datapoint?.filter(
          (item: any) => item?.para_id !== id
        );
        cardData.kdp_datapoint = filteredData;
      }
    }
    update_deviations(cardData);
  };

  const generateUniqueNumber = () => {
    const randomNumber = Math.random() * 1000000;
    const timestamp = Date.now().toString();
    const unique = randomNumber + parseInt(timestamp);
    return `${unique}`;
  };

  const addAnnotation = () => {
    const { Core } = instance;
    const { documentViewer } = Core;
    const selectedText = documentViewer.getSelectedText();
    if (!selectedText) {
      enqueueSnackbar(
        'Please select some text from the document on left to mark as Issue ',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    } else {
      if (selectedText && selectedText?.trim()?.split(/\s+/)?.length >= 5) {
        if (cardData?.manual_datapoint?.length > 0) {
          cardData.manual_datapoint = [
            { id: generateUniqueNumber(), field_value: selectedText },
            ...(cardData?.manual_datapoint || []),
          ];
        } else {
          cardData.manual_datapoint = [
            { id: generateUniqueNumber(), field_value: selectedText },
          ];
        }
        update_deviations(cardData);
      } else {
        enqueueSnackbar('Selected text must contain at least 5 words. ', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }
  };

  const markAsDeviation = () => {
    cardData.status = 'deviant';
    cardData.error = false;
    update_deviations(cardData);
  };

  const handleToggle = (id: string) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  const handleCloseOfViewPolicy = () => {
    setEditCopyDetailId(null);
    setOpenCreateApproval(false);
  };

  const handleOpenViewPolicy = (id: string) => {
    setEditCopyDetailId(id);
    setOpenCreateApproval(true);
  };

  useEffect(() => {
    if (allInsightsList?.length > 0) {
      allInsightsList?.map((listItem: any) => {
        if (
          cardData?.condition?.clause_name
            .toLowerCase()
            .includes(listItem?.clauseType)
        ) {
          setFieldValueDataOfKdp(listItem);
        }
      });
    }
  }, [cardData, allInsightsList]);

  const toShowNoIssueFoundText = () => {
    if (
      !cardData?.draft_datapoint &&
      (!cardData?.manual_datapoint ||
        cardData?.manual_datapoint.length === 0) &&
      (!cardData?.kdp_datapoint || cardData?.kdp_datapoint.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
          padding: '15px',
          background: '#88305F24',
          borderRadius: '10px',
        }}
      >
        <Stack alignItems="end" mb={1}>
          <Button
            variant="text"
            startIcon={<LaunchOutlinedIcon />}
            sx={{ padding: 0 }}
            disabled={!userIsOwner}
            onClick={() => handleOpenViewPolicy(cardData?.condition?.id)}
          >
            View approval policy
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Stack direction="row" spacing={2}>
            <Chip
              label={cardData?.status}
              style={{
                background: status[cardData?.status?.toLowerCase()],
                borderRadius: '5px',
              }}
            />
            <Typography fontSize="15px" fontWeight="700">
              Issue found in {cardData?.condition?.clause_name}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {cardData?.condition?.groups?.map((item: any, index: number) => (
              <Chip
                key={index}
                label={item?.name}
                style={{
                  background: '#DCBDE7',
                  borderRadius: '5px',
                  color: '#6D264C',
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Typography fontSize="14px" fontWeight="700">
            Defined policy name
          </Typography>
          <Box
            sx={{
              padding: '10px',
              background: '#FFF7FA',
              borderRadius: '10px',
            }}
          >
            <Typography fontSize="13px">
              {cardData?.condition?.policy_name}
            </Typography>
          </Box>
        </Stack>
        <Stack spacing={1} marginTop="15px">
          <Typography fontSize="14px" fontWeight="700">
            {descriptionHeading[cardData?.status?.toLowerCase()]}
          </Typography>
          {cardData?.draft_datapoint?.field_value && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                padding: '10px',
                background: '#FFF7FA',
                borderRadius: '10px',
              }}
            >
              <Typography fontSize="13px">
                {cardData?.draft_datapoint?.field_value}
              </Typography>
              {userIsOwner && !isDraftDropped && (
                <ActionButton
                  fieldValueId={cardData?.draft_datapoint?.id}
                  sentence={cardData?.draft_datapoint?.field_value}
                  instance={instance}
                  handleRemoveText={handleRemoveDeviationText}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                  openDeleteDialog={openDeleteDialog}
                  handleCloseDeleteDialog={handleCloseDeleteDialog}
                />
              )}
            </Stack>
          )}
          {cardData?.kdp_datapoint?.length > 0 && (
            <>
              {cardData?.kdp_datapoint?.map((item: any) => (
                <>
                  {item?.para || item?.sentence ? (
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="start"
                      sx={{
                        padding: '10px',
                        background: '#FFF7FA',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography fontSize="13px">
                        {showFullText?.id === item?.para_id &&
                        showFullText?.show
                          ? item?.para || item?.sentence
                          : handleSeeMoreAndLess(item?.para || item?.sentence)}
                        {(item?.para?.split(/\s+/)?.length > 20 ||
                          item?.sentence?.split(/\s+/)?.length > 20) && (
                          <span
                            onClick={() => handleToggle(item?.para_id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {showFullText?.id === item?.para_id &&
                            showFullText?.show
                              ? ' See less'
                              : 'See more'}
                          </span>
                        )}
                      </Typography>
                      {userIsOwner && !isDraftDropped && (
                        <ActionButton
                          fieldValueId={item?.para_id}
                          sentence={item?.para || item?.sentence}
                          instance={instance}
                          handleRemoveText={handleRemoveDeviationText}
                          setOpenDeleteDialog={setOpenDeleteDialog}
                          openDeleteDialog={openDeleteDialog}
                          handleCloseDeleteDialog={handleCloseDeleteDialog}
                        />
                      )}
                    </Stack>
                  ) : (
                    fieldValueDataOfKdp?.clauseData?.map(
                      (clauseDataItem: any) => (
                        <>
                          {item?.para_id === clauseDataItem?.para_id && (
                            <Stack
                              spacing={1}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="start"
                              sx={{
                                padding: '10px',
                                background: '#FFF7FA',
                                borderRadius: '10px',
                                cursor: 'pointer',
                              }}
                            >
                              <Typography fontSize="13px">
                                {showFullText?.id === item?.para_id &&
                                showFullText?.show
                                  ? clauseDataItem?.sentence
                                  : handleSeeMoreAndLess(
                                      clauseDataItem?.sentence
                                    )}
                                {clauseDataItem?.sentence?.split(/\s+/)
                                  ?.length > 20 && (
                                  <span
                                    onClick={() => handleToggle(item?.para_id)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {showFullText?.id === item?.para_id &&
                                    showFullText?.show
                                      ? ' See less'
                                      : 'See more'}
                                  </span>
                                )}
                              </Typography>
                              {userIsOwner && !isDraftDropped && (
                                <ActionButton
                                  fieldValueId={item?.para_id}
                                  sentence={clauseDataItem?.sentence}
                                  instance={instance}
                                  handleRemoveText={handleRemoveDeviationText}
                                  setOpenDeleteDialog={setOpenDeleteDialog}
                                  openDeleteDialog={openDeleteDialog}
                                  handleCloseDeleteDialog={
                                    handleCloseDeleteDialog
                                  }
                                />
                              )}
                            </Stack>
                          )}
                        </>
                      )
                    )
                  )}
                </>
              ))}
            </>
          )}
          {cardData?.manual_datapoint?.length > 0 && (
            <>
              {cardData?.manual_datapoint?.map(
                (valueItem: any, index: number) => (
                  <Stack
                    key={index}
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                    sx={{
                      padding: '10px',
                      background: '#FFF7FA',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography fontSize="13px">
                      {showFullText?.id === valueItem?.id && showFullText?.show
                        ? valueItem?.field_value
                        : handleSeeMoreAndLess(valueItem?.field_value)}
                      {valueItem?.field_value?.split(/\s+/)?.length > 20 && (
                        <span
                          onClick={() => handleToggle(valueItem?.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          {showFullText?.id === valueItem?.id &&
                          showFullText?.show
                            ? ' See less'
                            : 'See more'}
                        </span>
                      )}
                    </Typography>
                    {userIsOwner && !isDraftDropped && (
                      <ActionButton
                        fieldValueId={valueItem?.id}
                        sentence={valueItem?.field_value}
                        instance={instance}
                        handleRemoveText={handleRemoveDeviationText}
                        setOpenDeleteDialog={setOpenDeleteDialog}
                        openDeleteDialog={openDeleteDialog}
                        handleCloseDeleteDialog={handleCloseDeleteDialog}
                      />
                    )}
                  </Stack>
                )
              )}
            </>
          )}
          {toShowNoIssueFoundText() && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                padding: '10px',
                background: '#FFF7FA',
                borderRadius: '10px',
              }}
            >
              <Typography fontSize="13px">
                The language stipulated is not there
              </Typography>
            </Stack>
          )}
        </Stack>

        {cardData?.approval_status === null && (
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingY="15px"
            alignItems="center"
          >
            <Button
              variant="text"
              startIcon={<AddIcon />}
              disabled={!userIsOwner || isDraftDropped}
              onClick={() => {
                setIsShowAddMoreText(true);
              }}
            >
              Add more text
            </Button>
            <Tooltip
              title={droppedMsg}
              disableHoverListener={!isDraftDropped}
              arrow
            >
              <Stack>
                {cardData?.status === 'deviant' ? (
                  <Button
                    variant="outlined"
                    disabled={!userIsOwner || isDraftDropped}
                    onClick={() => setOpenRequestApproval(true)}
                  >
                    Request approval
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => markAsDeviation()}
                    disabled={!userIsOwner || isDraftDropped}
                  >
                    Mark as deviation
                  </Button>
                )}
              </Stack>
            </Tooltip>
          </Stack>
        )}
        {cardData?.approval_status === 'approval_pending' && (
          <Stack direction="row" paddingY="15px" justifyContent="end">
            <Chip
              label="Approval pending"
              icon={<WatchLaterOutlinedIcon />}
              style={{ padding: '15px 5px' }}
            />
          </Stack>
        )}
        {cardData?.approval_status === 'approved' && (
          <Stack direction="row" paddingY="15px" justifyContent="end">
            <Chip
              label="Approved"
              icon={<CheckIcon />}
              style={{ padding: '15px 5px' }}
            />
          </Stack>
        )}
        {isShowAddMoreText && (
          <Stack spacing={2} paddingBottom="20px" marginX="10px">
            <Typography fontWeight={700}>
              1. Select some text from the document on left to mark as Issue
            </Typography>
            <Typography fontWeight={700}>2. Confirm action</Typography>
            <Stack direction="row">
              <Button variant="contained" onClick={() => addAnnotation()}>
                Add selected text to current Issue
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsShowAddMoreText(false);
                }}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
      <RequestApproval
        open={openRequestApproval}
        onClose={handleCloseRequestApprovalDialog}
        cardData={cardData}
        priority={cardData?.condition?.priority}
        combinedApproversIds={combinedApproversIds}
      />
      {openCreateApproval && (
        <CreateApprovalComponent
          open={openCreateApproval}
          onClose={handleCloseOfViewPolicy}
          editDetails={false}
          editCopyDetailId={editCopyDetailId}
          isViewPolicy={true}
        />
      )}
    </>
  );
};

export default DeviationCard;
