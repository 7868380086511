import React, { useCallback, useEffect, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import { sortListAlphabetical } from '../Draft/Component/Helper';
import { IObject } from '../interfaces/IObject';
import OverflowTip from '../RiverusUI/Components/OverflowTip';
import DataGridTable from '../RiverusUI/DataGrid/DataGridTable';
import TableHeaderWithSearch from '../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../RiverusUI/DataGrid/TableSearchSelectInput';
import {
  getAuditTypes,
  getEmailAudit,
  getSenderEmails,
} from '../Services/authApi';

const initialFilter = {
  status: '',
  to_address: '',
  email_type: '',
  created: '',
};

const EmailAudit = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filters, setFilters] = useState<any>({});
  const [searchSender, setSearchSender] = useState<boolean>(false);
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  const [searchToAddress, setSearchToAddress] = useState<boolean>(false);
  const [searchEmailType, setSearchEmailType] = useState<boolean>(false);
  const [draftTableKey, setDraftTableKey] = useState<number>(0);
  const [refreshKey, setRefreshKey] = useState(false);

  const { data: senderEmails } = useQuery({
    queryKey: ['sender-emails'],
    queryFn: getSenderEmails,
    select: (response: any) => {
      const emails =
        response?.data?.map((email: string) => {
          return {
            name: email,
          };
        }) || [];
      return [...emails, { name: 'clm_notifications@riverus.in' }];
    },
  });

  const { data: emailStatus } = useQuery({
    queryKey: ['emails-status'],
    queryFn: () =>
      getAuditTypes({
        search_type: 'status',
      }),
    select: (response: any) => {
      const status =
        response?.status?.map((status: string) => {
          return {
            name: status,
          };
        }) || [];
      return status;
    },
  });

  const { data: emailTypes } = useQuery({
    queryKey: ['emails-types'],
    queryFn: () =>
      getAuditTypes({
        search_type: 'email_type',
      }),
    select: (response: any) => {
      const email_types =
        response?.email_types?.map((emailType: string) => {
          return {
            name: emailType?.replace(/_/g, ' '),
            value: emailType,
          };
        }) || [];
      return email_types;
    },
  });

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumber(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const {
    data: emailAuditDetails,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: [
      'email-audit-details',
      pageNumber,
      sorting,
      filters,
      draftTableKey,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }

      let sortingParam = '';

      if (sorting?.length > 0) {
        for (let i = 0; i < sorting?.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i]?.field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i]?.field}`;
          }
        }
      } else {
        sortingParam = '';
      }
      const page = pageNumber + 1;
      const params = `?page=${page}${sortingParam}${filterParam}`;
      return getEmailAudit(params);
    },
  });

  const handleStatusClick = React.useCallback(
    (status: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        status: [status],
      }));
      if (status) {
        setPageNumber(0);
      }
      setSearchStatus(true);
    },
    [handleFilterChange]
  );

  const handleEmailTypeClick = React.useCallback(
    (type: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        email_type: [type],
      }));
      if (type) {
        setPageNumber(0);
      }
      setSearchEmailType(true);
    },
    [handleFilterChange]
  );

  const columns = [
    {
      field: 'sender',
      headerName: 'Sender',
      minWidth: 240,
      flex: 1,
      sortable: false,
      // searching is not required for now but may be require in future
      // renderHeader: () => {
      //   return searchSender ? (
      //     <TableSearchInputComponent
      //       key="sender"
      //       setIsSearch={() => {
      //         setFilters((prev: any) => ({ ...prev, sender: null }));
      //         setSearchSender(false);
      //       }}
      //       placeholder="Search Sender"
      //       renderCustomInput={() => (
      //         <TableAsyncSelectInput
      //           key={
      //             isArray(filters?.sender)
      //               ? (filters?.sender?.[0] as string)
      //               : 'sender'
      //           }
      //           options={sortListAlphabetical(senderEmails)}
      //           label="Select Sender"
      //           valueKey="name"
      //           value={filters?.sender ? filters.sender : []}
      //           onChange={(value: string[]) =>
      //             handleFilterChange('sender', value)
      //           }
      //         />
      //       )}
      //     />
      //   ) : (
      //     <TableHeaderWithSearch title="Sender" setIsSearch={setSearchSender} />
      //   );
      // },
      renderCell: (params: any) =>
        params?.row?.sender
          ? params?.row?.sender
          : 'clm_notifications@riverus.in',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 240,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchStatus ? (
          <TableSearchInputComponent
            key="status"
            setIsSearch={() => {
              setFilters((prev: any) => ({ ...prev, status: null }));
              setSearchStatus(false);
            }}
            placeholder="Search Status"
            renderCustomInput={() => (
              <TableSearchSelectInput
                key={
                  isArray(filters?.status)
                    ? (filters?.status?.[0] as string)
                    : 'status'
                }
                options={sortListAlphabetical(emailStatus)}
                label="Select Status"
                valueKey="name"
                value={filters?.status ? filters.status : []}
                onChange={(value: string[]) => {
                  if (value.length > 0) {
                    handleFilterChange('status', value);
                  } else {
                    setFilters((prev: IObject) => ({
                      ...prev,
                      status: null,
                    }));
                  }
                }}
                isLoading={isLoading}
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch title="Status" setIsSearch={setSearchStatus} />
        );
      },
      renderCell: (Params: any) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Chip
              sx={{
                background: '#88305f3d',
                color: 'riSecondary.900',
              }}
              label={<OverflowTip value={Params?.row?.status} />}
              onClick={() => handleStatusClick(Params?.row?.status)}
            />
          </Stack>
        );
      },
    },
    {
      field: 'to_address',
      headerName: 'To Address',
      minWidth: 90,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchToAddress ? (
          <TableSearchInputComponent
            key="to_address"
            setIsSearch={() => {
              setFilters((prev: any) => ({ ...prev, to_address: null }));
              setSearchToAddress(false);
            }}
            placeholder="Search To Address"
            handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
              handleFilterChange('to_address', e.target.value)
            }
          />
        ) : (
          <TableHeaderWithSearch
            title="To Address"
            setIsSearch={setSearchToAddress}
          />
        );
      },
    },
    {
      field: 'email_type',
      headerName: 'Email Type',
      minWidth: 260,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return searchEmailType ? (
          <TableSearchInputComponent
            key="email_type"
            setIsSearch={() => {
              setFilters((prev: any) => ({ ...prev, email_type: null }));
              setSearchEmailType(false);
            }}
            placeholder="Search Email Type"
            selectedValue={filters?.email_type ? filters.email_type : []}
            renderCustomInput={() => (
              <TableSearchSelectInput
                key={
                  isArray(filters?.email_type)
                    ? (filters?.email_type?.[0] as string)
                    : 'email_type'
                }
                label="Select contract type"
                options={sortListAlphabetical(emailTypes || [])}
                valueKey="value"
                value={filters?.email_type ? filters.email_type : []}
                onChange={(value: string[]) => {
                  if (value.length > 0) {
                    handleFilterChange('email_type', value);
                  } else {
                    setFilters((prev: IObject) => ({
                      ...prev,
                      email_type: null,
                    }));
                  }
                }}
                isLoading={isLoading}
              />
            )}
          />
        ) : (
          <TableHeaderWithSearch
            title="Email Type"
            setIsSearch={setSearchEmailType}
          />
        );
      },
      renderCell: (params: any) => (
        <Chip
          sx={{
            background: '#C4DBFF',
            color: 'riSecondary.900',
          }}
          label={
            <OverflowTip
              value={params?.row?.email_type?.replace(/_/g, ' ') || '--'}
            />
          }
          onClick={() => handleEmailTypeClick(params?.row?.email_type)}
        />
      ),
    },
    {
      field: 'created',
      headerName: 'Created Date',
      minWidth: 160,
      flex: 1,
      renderCell: (params: any) =>
        dayjs(params?.row?.created)?.format('DD/MM/YYYY, h:mm A'),
    },
  ];

  const handleRefresh = useCallback(() => {
    setDraftTableKey(Math.random());
    setFilters(initialFilter);
    setSorting([]);
    setRefreshKey((prevKey) => !prevKey);
  }, []);

  useEffect(() => {
    if (!filters?.status) {
      setSearchStatus(false);
    }
    if (!filters?.to_address) {
      setSearchToAddress(false);
    }
    if (!filters?.email_type) {
      setSearchEmailType(false);
    }
  }, [refreshKey]);

  return (
    <Stack spacing={2} alignItems="start">
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#88305F',
        }}
      >
        Email Audit
      </Typography>
      <IconButton>
        <RefreshIcon onClick={handleRefresh} />
      </IconButton>
      <DataGridTable
        rows={emailAuditDetails?.results || []}
        columns={columns}
        rowCount={emailAuditDetails?.count}
        isLoading={isLoading || isPending}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumber}
        setSorting={setSorting}
        checkboxSelection={false}
      />
    </Stack>
  );
};

export default EmailAudit;
