/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import WebViewer from '@pdftron/webviewer';
import { useMutation } from '@tanstack/react-query';

import { APRYSE_LICENSE_KEY } from '../../Configuration/global';
import SettingIcon from '../../RiverusUI/Components/Icons/SettingIcon';
import { getS3ReferencePresignedURL } from '../../Services/Draft';

interface Props {
  setComparisonDrawer: any;
  openComparisonDrawer: boolean;
  draftDetails: any;
  selectedDraftsLink: any;
  onClose: VoidFunction;
  compareFileName: string[];
  comparisonResponse?: any;
}

const ComparisonDialog: React.FC<Props> = ({
  setComparisonDrawer,
  openComparisonDrawer,
  draftDetails,
  selectedDraftsLink,
  onClose,
  compareFileName,
  comparisonResponse,
}) => {
  const [inta, setInstance] = useState<any>('');
  // const [enableKDP] = React.useState(false);
  // const [annotationIds, setannotationIds] = React.useState<any>([]);
  const [isSearch, setSearch] = useState<any>(false);

  const documentContainer = useRef<HTMLDivElement>(null);

  const { mutateAsync: presignedUrl1 } = useMutation({
    mutationKey: ['get_first_presigned_url'],
    mutationFn: () =>
      getS3ReferencePresignedURL({
        body: {
          file_type: 'view_document',
          link:
            comparisonResponse?.compare_versions?.data?.[0]?.link ||
            comparisonResponse?.compare_versions?.data?.[0]?.template_link,
        },
      }),
  });

  const { mutateAsync: presignedUrl2 } = useMutation({
    mutationKey: ['get_second_presigned_url'],
    mutationFn: () =>
      getS3ReferencePresignedURL({
        body: {
          file_type: 'view_document',
          link:
            comparisonResponse?.compare_versions?.data?.[1]?.link ||
            comparisonResponse?.compare_versions?.data?.[1]?.template_link,
        },
      }),
  });

  const convertPDF = async (Core: any, fileURL: any) => {
    // perform the conversion with no optional parameters
    try {
      const buf = await Core.officeToPDFBuffer(fileURL);

      //optionally save the blob to a file or upload to a server
      const blob = new Blob([buf], { type: 'application/pdf' });
      return blob;
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      if (documentContainer.current != null) {
        WebViewer.Iframe(
          {
            fullAPI: true,
            disableMultiViewerComparison: true,
            path: '/webviewer',
            disabledElements: ['header', 'toolsHeader'],
            loadAsPDF: true,
            ui: 'legacy',
            licenseKey: APRYSE_LICENSE_KEY,
          },
          documentContainer.current
        ).then((instance: any) => {
          const { UI, Core } = instance;
          const { Annotations } = Core;
          const { Color } = Annotations;

          setInstance(instance);
          instance.UI.openElements(['loadingModal']);

          UI.addEventListener(UI.Events.MULTI_VIEWER_READY, async () => {
            const [documentViewer1, documentViewer2] =
              Core.getDocumentViewers();

            const startCompare = async () => {
              const shouldCompare =
                documentViewer1.getDocument() && documentViewer2.getDocument();

              if (shouldCompare) {
                // Check if both documents loaded before comparing
                const beforeColor = new Color(255, 73, 73, 0.4);
                const afterColor = new Color(21, 205, 131, 0.4);
                const options = {
                  beforeColor,
                  afterColor,
                };

                await documentViewer1.startSemanticDiff(
                  documentViewer2,
                  options
                );
              }
            };

            const options1 = { filename: `${compareFileName[0]}.pdf` };
            const options2 = { filename: `${compareFileName[1]}.pdf` };

            const iframeDoc = instance.UI.iframeWindow.document;

            //access the container element by id of each view
            const leftView = iframeDoc.getElementById('container1');
            const rightView = iframeDoc.getElementById('container2');

            //drop area is the first child of the container when document is not loaded
            leftView.firstChild.style.display = 'none';
            rightView.firstChild.style.display = 'none';

            const leftCross = iframeDoc.getElementById('header1');
            const rightCross = iframeDoc.getElementById('header2');

            leftCross.lastChild.lastChild.style.display = 'none';
            rightCross.lastChild.lastChild.style.display = 'none';

            try {
              const response = await presignedUrl1();
              if (response?.response?.presigned_url) {
                const blob1 = await convertPDF(
                  Core,
                  response?.response?.presigned_url
                );
                documentViewer1.loadDocument(blob1, options1);
              }
            } catch (error) {
              console.error('Error fetching first presigned URL:', error);
            }

            try {
              const response = await presignedUrl2();
              if (response?.response?.presigned_url) {
                const blob2 = await convertPDF(
                  Core,
                  response?.response?.presigned_url
                );
                documentViewer2.loadDocument(blob2, options2);
              }
            } catch (error) {
              console.error('Error fetching second presigned URL:', error);
            }

            documentViewer1.addEventListener(
              Core.DocumentViewer.Events.DOCUMENT_LOADED,
              startCompare
            );

            documentViewer2.addEventListener(
              Core.DocumentViewer.Events.DOCUMENT_LOADED,
              startCompare
            );

            instance.UI.closeElements(['loadingModal']);
          });
          instance.UI.enterMultiViewerMode();
        });
      }
    }, 200);
  }, [documentContainer.current, selectedDraftsLink]);

  const [isDark, setTheme] = useState(false);

  const darkTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.DARK);
  };
  const lightTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.LIGHT);
  };

  const search = () => {
    setSearch(true);
    inta.UI.searchText('', {
      caseSensitive: true,
      wholeWord: true,
    });
  };

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      open={openComparisonDrawer}
      className="draft-main"
    >
      <DialogTitle>
        <Box p={0}>
          <Stack
            direction="row"
            gap={1}
            style={{ fontSize: '16px' }}
            alignItems="center"
          >
            <IconButton
              onClick={() => {
                setComparisonDrawer(false);
                onClose();
              }}
            >
              <ArrowBackIcon sx={{ width: '15px' }} />
            </IconButton>
            Compare Draft
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ margin: 0, padding: '16px 0' }}>
        <Box height="80vh">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p="0 15px"
          >
            <IconButton
              onClick={() => {
                setTheme(!isDark);
                if (isDark) {
                  lightTheme();
                } else {
                  darkTheme();
                }
              }}
            >
              <SettingIcon color="#88305F" />
            </IconButton>
            <IconButton
              onClick={() => {
                if (isSearch) {
                  inta.UI.closeElements(['searchPanel']);
                  setSearch(false);
                } else {
                  search();
                }
              }}
            >
              <SettingIcon color="#88305F" />
            </IconButton>
          </Stack>
          <Box
            ref={documentContainer}
            id="document-viewer"
            sx={{ height: '100%', padding: 2 }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ComparisonDialog;
