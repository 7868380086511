import React from 'react';

import { Dialog, Link, Stack, Typography } from '@mui/material';

interface IProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const DraftInActiveAlert: React.FC<IProps> = ({ isOpen }) => {
  return (
    <Dialog open={isOpen}>
      <Stack width="fit-content" padding={3} spacing={2} alignItems="center">
        <Typography>
          This draft is inactive. So, you can not perform any action.
        </Typography>
        <Stack direction="row" alignSelf="center">
          <Link
            href="/draftingreview"
            bgcolor="riPrimary.500"
            color="white"
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 15px',
              borderRadius: '5px',
            }}
          >
            Go back
          </Link>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DraftInActiveAlert;
