import React, { FC, useCallback } from 'react';

import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import { addSections } from '../../../Services/dictionary';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  id: string;
}

const AddSectionModal: FC<IProps> = (props) => {
  const { open, onClose, id } = props;
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: addSectionMutation } = useMutation({
    mutationKey: ['add_section'],
    mutationFn: addSections,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dictionary-fields', id] });
      enqueueSnackbar('Section added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.error;
      enqueueSnackbar(`${responseData || 'Failed to create Section!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = useCallback(
    (data: any) => {
      addSectionMutation({
        ...data,
        section_tag: `::${data.section_name}::`,
        contract_type: id,
        creation_type: 'custom',
      });
    },
    [addSectionMutation, id]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Add Contract Type"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: '400px', padding: '30px 10px' }} gap={2}>
            <ControlledTextField
              name="section_name"
              label="Section Name"
              placeholder="Enter the section name"
              control={control}
              required
            />
            <ControlledTextField
              name="section_description"
              label="Section Description"
              placeholder="Enter the section description"
              control={control}
            />
            <Button variant="contained" type="submit">
              Add Section
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default AddSectionModal;
