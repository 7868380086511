import axios from 'axios';

import { authApi } from './authApi';
import AUTH0 from '../auth_config';
import { getSessionStorage } from '../Authentication/Actions/authentication';
import { PresignedDataField } from '../Draft/State/DraftState';

const APIPaths = {
  draftBaseUrl: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
  getRequisition:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/requisition_form/',
  getCompareVersion:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/compare_version/',
  getTemplates: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/templates/',
  getChecklist:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_checklist/',
  createChecklist: process.env.REACT_APP_RIVERUS_CLM_API + 'admin/checklist/',
  uploadDocUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft_checklist_supportdoc/',
  getContractTemplates: process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/',
  getTemplatePresignedURL: `${AUTH0.apiBasePath}stylus/templates/aws/presigned-url/`,
  getS3PresignedURL: `${AUTH0.apiBasePath}stylus/aws/presigned-url/`,
  getOptionalFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/optional_field/',
  saveCompleteDraftFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/bulk_optional_field/',
  bulkUpdateProjects:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft/bulk-update-projects/',
  getFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/get_field',
  getCollaborators:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Collaborators&assignee_type=internal',
  getApprovals:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval/',
  approvalComments:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval_comment/',
  checklistComments:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/checklist-comment/',
  requestApproval:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/request_approval/',
  getDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_deviation/',
  getMergeTagDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/merge-tag-deviation/',
  draft_deviation:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_deviation/',
  draft_approval:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval/',
  referenceDocument:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/reference_document/',
  emailApi:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/approval-email/',
  downloadFileUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft_checklist_supportdoc/',
  externalUsers: process.env.REACT_APP_RIVERUS_CLM_API + 'users/',
  externalUsersList:
    process.env.REACT_APP_RIVERUS_CLM_API + 'users/?assignee_type=external',
};

const axiosConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};

export const fetchExecutedContractsById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.getContractTemplates}download/${id}/`);
};

export const fetchSupportDocsDownloadUrl = async (id: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.downloadFileUrl}${id}/download-checklist/`
  );
};

export const editDraftData = async (payload: any) => {
  return await authApi.patch(
    `${APIPaths.draftBaseUrl}${payload?.id}/`,
    payload?.body
  );
};

export const bulkUpdateCategory = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.draftBaseUrl}bulk-update-category/`,
    payload
  );
};

export const fetchCollaborators = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getCollaborators}`);
};

export const fetchTemplate = async (): Promise<any> => {
  return await authApi.get(APIPaths?.getTemplates);
};

export const fetchDrafts = async (params?: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths.draftBaseUrl}get_latest/?filter_parameter=Group${
      params ? params : ''
    }`
  );
};

export const fetchDateStatistics = async (params?: string): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}statistics/${params}`);
};

export const createDraft = async (payload: any) => {
  return await authApi.post(APIPaths.draftBaseUrl, payload);
};

export const sendEmail = async (payload: any) => {
  return await authApi.post(APIPaths.emailApi, payload);
};

export const updateDraft = async (id: string, body: any) => {
  return await authApi.put(`APIPaths.createApproval${id}/`, body);
};

export const fetchDraftById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}${id}/`);
};

export const fetchStatistics = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}statistics/`);
};

export const deleteDraft = async (ids: string[]) => {
  const data = { delete_list: ids, type: 'drafts' };
  return await authApi.delete(`${APIPaths.draftBaseUrl}`, {
    headers: {
      Authorization: `Bearer ${getSessionStorage(`accessToken`)}`,
    },
    data,
  });
};

export const fetchVersionHistory = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}?search=${id}`);
};

export const fetchRequisitionVersionHistory = async (
  id: string
): Promise<any> => {
  return await authApi.get(`${APIPaths?.getRequisition}?search=${id}`);
};

export const deleteCompareVersion = async (ids: string[]) => {
  const data = { delete_list: ids };
  return await authApi.delete(`${APIPaths.getCompareVersion}`, {
    data,
  });
};

export const deleteDraftVersionHistory = async (ids: string[]) => {
  const data = { delete_list: ids, type: 'version_drafts' };
  return await authApi.delete(`${APIPaths.draftBaseUrl}`, {
    data,
  });
};

export const fetchChecklistData = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.getChecklist}?draft=${id}`);
};

export const fetchRequisitionVersionById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.getRequisition}${id}/`);
};

export const fetchDraftVersionId = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}${id}/`);
};

export const fetchCompareVersionHistory = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.getCompareVersion}?search=${id}`);
};

export const updateVersion = async (id: string) => {
  return await authApi.patch(`${APIPaths.getRequisition}/${id}/`, {
    draft_created: true,
  });
};

export const updateChecklist = async (payload: any) => {
  return await authApi.put(
    `${APIPaths?.getChecklist}${payload?.id}/`,
    payload?.body
  );
};

export const updateActiveStatus = async (payload: any) => {
  return await authApi.patch(
    `${APIPaths.getChecklist}${payload.id}/`,
    payload.body
  );
};

export const deleteChecklist = async (id: string) => {
  return await authApi.delete(`${APIPaths?.getChecklist}${id}/`);
};

export const createChecklist = async (payload: any) => {
  return await authApi.post(APIPaths?.createChecklist, payload);
};

export const addNewChecklist = async (payload: any) => {
  return await authApi.post(APIPaths?.getChecklist, payload);
};

export const getRequisitionById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.getRequisition}${id}/`);
};

export const getDraftById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}${id}/`);
};

export const getKDPDataPoints = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}insights/${id}/`);
};

export const fetchAssignee = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.draftBaseUrl}assignees/`);
};

export const saveDraft = async (payload?: any) => {
  return await authApi.post(
    `${APIPaths.draftBaseUrl}bulk_optional_field/`,
    payload
  );
};

export const uploadNewDocument = async (payload: any) => {
  return await authApi.post(`${APIPaths?.uploadDocUrl}`, payload);
};

export const upload_file_in_s3_bucket = async ({
  presignedPostData,
  file,
  onHandleFileProgress,
}: {
  presignedPostData: any;
  file?: any;
  onHandleFileProgress?: any;
}) => {
  const { fields, url } = presignedPostData;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof PresignedDataField]);
  });

  file && formData.append('file', file);

  const config = onHandleFileProgress
    ? { axiosConfig, ...onHandleFileProgress }
    : axiosConfig;

  return await axios.post(url, formData, config).then(() => presignedPostData);
};

export const fetchTemplateById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.getTemplates}${id}/`);
};

export const fetchExecutedContracts = async (payload: {
  id: string;
  page: number;
  page_size: number;
  groups: string;
}): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.getContractTemplates}?contract_type=${payload.id}&page=${payload.page}&page_size=${payload.page_size}${payload?.groups}`
  );
};

export const fetchEarlierDrafts = async (payload: any): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.draftBaseUrl}get_latest/?filter_parameter=Group&filter_type=earlier_draft&contractType=${payload?.name}&page=${payload?.page}&page_size=${payload.page_size}${payload?.groups}`
  );
};

export const getS3PresignedUrl = async (fileInfo: any) => {
  return await authApi.post(
    fileInfo.file_type === 'template' ||
      fileInfo.file_type === 'support_doc_template'
      ? APIPaths.getTemplatePresignedURL
      : APIPaths.getS3PresignedURL,
    { ...fileInfo }
  );
};

export const getS3ReferencePresignedURL = async (
  payload: any
): Promise<any> => {
  const response = await authApi.post(APIPaths.getS3PresignedURL, payload.body);
  return {
    response,
    type: payload.type || '',
  };
};

export const referenceDocument = async (payload: any) => {
  return await authApi.post(APIPaths.referenceDocument, payload);
};

export const fetchReferenceDocument = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.referenceDocument}?draft=${id}`);
};

export const fetchOptionalFields = async (draft_uuid: string): Promise<any> => {
  return await authApi.get(`${APIPaths.getOptionalFields}${draft_uuid}/`);
};

export const fetchGetFields = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.getFields}?contract_type=${id}`);
};

export const fetchKDPTagDeviations = async (
  id: string,
  type: string
): Promise<any> => {
  return await authApi.get(
    `${APIPaths.getDeviations}?draft=${id}&deviation_type=${type}`
  );
};

export const fetchMergeTagDeviations = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.getMergeTagDeviations}${id}/`);
};

export const deleteSupportDocFromChecklist = async (id: string) => {
  const payload = {
    deleted_status: true,
  };
  return await authApi.patch(`${APIPaths?.uploadDocUrl}${id}/`, payload);
};

export const getDraftApprovals = async (
  id: string,
  type: string
): Promise<any> => {
  return await authApi.get(
    `${APIPaths.getApprovals}?draft=${id}&approval_type=${type}`
  );
};

export const addDraftApprovalComments = async (payload: any) => {
  return await authApi.post(APIPaths.approvalComments, payload);
};

export const deleteApprovalComment = async (id: string) => {
  return await authApi.delete(`${APIPaths.approvalComments}${id}/`);
};

export const addDraftApprovalBulkComments = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.approvalComments}comments-bulk-update/`,
    payload
  );
};

export const addDraftChecklistComments = async (payload: any) => {
  return await authApi.post(APIPaths.checklistComments, payload);
};

export const deleteChecklistComment = async (id: string) => {
  return await authApi.delete(`${APIPaths.checklistComments}${id}/`);
};

export const addDraftChecklistBulkComments = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.checklistComments}comments-bulk-update/`,
    payload
  );
};

export const updateDeviationApprovals = async (id: string, payload: any) => {
  return await authApi.patch(`${APIPaths.requestApproval}${id}/`, payload);
};

export const draftDeviation = async (payload: any) => {
  return await authApi
    .post(APIPaths.draft_deviation, payload)
    .catch((error) => {
      console.error('Error while create merge tag', error);
    });
};

export const updateDeviations = async (id: string, payload: any) => {
  return await authApi.patch(`${APIPaths.draft_deviation}${id}/`, payload);
};

export const requestApproval = async (payload: any) => {
  return await authApi.post(APIPaths?.draft_approval, payload);
};

export const deleteCustomDeviation = async (id: string) => {
  return await authApi.delete(`${APIPaths?.draft_deviation}${id}/`);
};

export const requestAccess = async (payload: any) => {
  return await authApi.post(`${APIPaths.draftBaseUrl}draft-lock/`, payload);
};

export const deleteCompleteDraftField = async (id: string) => {
  return await authApi.delete(`stylus/draft/optional_field/delete/${id}/`);
};

export const send_Email = async (payload: any) => {
  return await authApi.post('stylus/draft/send-email/', payload);
};

export const externalUsers = async (payload: any) => {
  return await authApi.post(`${APIPaths.externalUsers}`, payload);
};

export const editExternalUsers = async (payload: any) => {
  return await authApi.patch(`users/${payload.id}/`, payload.body);
};

export const deleteExternalUser = async (payload: any) => {
  return await authApi.patch(`users/${payload?.id}/`, payload?.body);
};

export const fetchExternalUsersList = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.externalUsersList}`);
};

export const assigneesList = async (payload?: any) => {
  return await authApi.post(`${APIPaths.draftBaseUrl}assignees-list/`, payload);
};

export const fetchPolicyContractTypes = async (): Promise<any> => {
  return await authApi.get('admin/contracttype/?has_requisition_policy=true');
};

export const removeChecklistAssignment = async (payload: any) => {
  return await authApi.post(
    'stylus/draft_checklist/remove-checklist-assignment/',
    payload
  );
};

export const getPreSignatoryApprovers = async (payload: any) => {
  return await authApi.post('stylus/draft/pre-signatory-approvers/', payload);
};

export const getSignatureStatus = async (id: string): Promise<any> => {
  return await authApi.get(`stylus/email-bounced/?draft=${id}`);
};

export const getInternalUsers = async (): Promise<any> => {
  return await authApi.get('users/?assignee_type=internal');
};

export const dropUnDropDraft = async (payload: any) => {
  return await authApi.patch('stylus/draft/drop/', payload);
};

export const exportStylusExcel = async (params = ''): Promise<any> => {
  return await authApi.get(`stylus/draft/export-drafts-email/${params}`);
};

export const boostWithAi = async (payload: any) => {
  return await authApi.post(`${APIPaths.draftBaseUrl}boost-with-ai/`, payload);
};

export const bulkUpdateProjects = async (payload: any) => {
  return await authApi.post(APIPaths.bulkUpdateProjects, payload);
};
