import React, { useEffect, useMemo } from 'react';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { fetchAssigneeList } from '../../../Services/Access';
import {
  addCoOwner,
  fetchContractById,
  fetchGroups,
} from '../../../Services/DocumentLibrary';
import ShareChip from '../ShareModal/ShareChip';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  contractId: GridRowId[];
}

const AddCoOwner: React.FC<IProps> = ({ open, onClose, contractId }) => {
  const { data: contract_data } = useQuery({
    queryKey: ['get_contract_data_by_id', contractId?.[0]],
    queryFn: async () => await fetchContractById(contractId?.[0] as string),
    enabled: !!contractId?.[0],
  });

  const { data: assigneeData, isLoading } = useQuery({
    queryKey: ['co-owner-assignee'],
    queryFn: fetchAssigneeList,
    select: (response: any) => {
      const users = response?.users || [];
      const modifiedUsers = users.map((user: any) => {
        const name = user.first_name + ' ' + user.last_name;
        return { ...user, name };
      });
      return modifiedUsers || [];
    },
  });

  const { control, handleSubmit, reset, setValue } = useForm();

  const ownerIds = useMemo(() => {
    return contract_data?.owners?.map((owner: any) => owner?.id) || [];
  }, [contract_data?.owners]);

  useEffect(() => {
    const assignedOwners =
      ownerIds?.length > 0
        ? ownerIds
        : [contract_data?.creator_details?.id, ...ownerIds];

    setValue('owners', assignedOwners);
  }, [contract_data?.creator_details?.id, ownerIds]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleClose = () => {
    reset();
    onClose();
  };

  const { mutate: add_Co_Owner, isPending } = useMutation({
    mutationFn: addCoOwner,
    onSuccess: () => {
      enqueueSnackbar('Successfully added co-owner!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleClose();
      queryClient.invalidateQueries({ queryKey: ['contract_table_data'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to add co-owner!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: groupData } = useQuery({
    queryKey: ['group_data'],
    queryFn: fetchGroups,
  });

  const onSubmit = (data: any) => {
    if (!data?.owners?.length) {
      enqueueSnackbar('Please select co-owner!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      const filterUsers = assigneeData?.filter((item: any) =>
        data?.owners?.includes(item?.id)
      );

      const groups = contract_data?.groups;

      filterUsers?.forEach((item: any) => {
        item?.groups?.forEach((group: string) => {
          if (groups.includes(group)) {
            return;
          } else {
            groups.push(group);
          }
        });
      });

      const groupIds: number[] = [];

      groupData?.forEach((groupItem: any) => {
        if (groups?.includes(groupItem?.name)) {
          groupIds.push(groupItem?.id);
        } else return;
      });

      const payload = {
        id: contractId?.[0],
        body: { ...data, groups: groupIds },
      };
      add_Co_Owner(payload);
    }
  };

  return (
    <CustomModal title="Add Co-owner" open={open} handleClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} width="500px" pt="20px">
          <RISelectComponent
            name="owners"
            control={control}
            label="Select Co-owner *"
            options={sortListAlphabetical(assigneeData)}
            loading={isLoading}
            isMultiselect={true}
            disableOptionCondition={(assignee: any) => !assignee?.is_active}
            renderCustomComponent={(value: any, props) => (
              <ShareChip
                key={value?.id}
                icon={<PersonOutlineOutlinedIcon />}
                label={value?.name}
                sx={{
                  background: !value?.is_active
                    ? 'rgba(0, 0, 0, 0.15)'
                    : '#FFECF1',
                  padding: '15px 10px',
                  color: !value?.is_active ? '' : '#6D264C',
                  borderRadius: '5px',
                }}
                {...props}
              />
            )}
            renderAction={(value) => {
              return value?.is_active ? (
                <></>
              ) : (
                <Stack direction="row" color="#88305f" fontWeight={600}>
                  Inactive
                </Stack>
              );
            }}
          />
          <Stack direction="row" spacing={1}>
            <LoadingButton
              loading={isPending}
              type="submit"
              variant="contained"
            >
              Add Co-owner
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default AddCoOwner;
