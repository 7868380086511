import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ApprovalCard from './ApprovalCard';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import { getDraftApprovals } from '../../../Services/Draft';
import { fetchExtractedData } from '../../../Services/Insights';
import { draftStatus } from '../../State/DraftState';
import { isStatusMatched } from '../Helper';

interface IProps {
  draftData: any;
  approvalData: any;
  refreshTriggered: boolean;
  setDisableGenerateButton: Dispatch<SetStateAction<boolean>>;
  instance: any;
}

const ApprovalTab: React.FC<IProps> = ({
  draftData,
  approvalData,
  refreshTriggered,
  setDisableGenerateButton,
  instance,
}) => {
  const { data: extractedData, isLoading } = useQuery({
    queryKey: ['clause_term', draftData?.id],
    queryFn: () => fetchExtractedData(draftData?.id),
    enabled: !!(
      draftData?.id &&
      (isStatusMatched(draftData?.status) ||
        isStatusMatched(draftData?.drop_status))
    ),
  });

  const { data: data, isLoading: approvalLoading } = useQuery({
    queryKey: [
      'draft_approvals',
      draftData?.id,
      draftData?.version,
      draftData?.pre_signatories,
    ],
    queryFn: () => {
      const approval_type = draftData?.pre_signatories?.length
        ? 'pre_signatory_approvers'
        : 'deviation_approvers';
      const type =
        draftData?.version === 0 ? 'requisition_approvers' : approval_type;
      return getDraftApprovals(draftData?.id, type);
    },
    select: (response: any) => response?.results,
    enabled: !!draftData?.id,
  });

  const dataToMap = useMemo(() => {
    if (refreshTriggered) return approvalData;
    if (draftData?.status === draftStatus?.PRE_SIGNATORIES_APPROVAL_PENDING)
      return data;
    return data;
  }, [
    approvalData,
    data,
    refreshTriggered,
    draftData?.status,
    draftStatus?.PRE_SIGNATORIES_APPROVAL_PENDING,
  ]);

  const disableGenerateButton = useMemo(() => {
    if (!dataToMap?.length) return false;
    return !dataToMap.every((approver: any) => approver?.status === 'approved');
  }, [dataToMap]);

  useEffect(() => {
    setDisableGenerateButton(disableGenerateButton);
  }, [disableGenerateButton, setDisableGenerateButton]);

  if (approvalLoading || isLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }

  return (
    <Stack spacing={1}>
      {dataToMap?.length > 0 && (
        <>
          {dataToMap?.map((approvals: any) => (
            <ApprovalCard
              key={approvals?.id}
              approvals={approvals}
              extractedData={extractedData}
              draftData={draftData}
              approvalDataList={dataToMap}
              instance={instance}
            />
          ))}
        </>
      )}

      {dataToMap?.length === 0 && (
        <Typography p="10px">No pending approvals</Typography>
      )}
    </Stack>
  );
};

export default ApprovalTab;
