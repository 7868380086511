import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { sortListAlphabetical } from './Helper';
import { restrictionStatus } from './StaticData';
import { useUserData } from '../../App/Component/UserDataProvider';
import CustomModal from '../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../RiverusUI/Components/SelectComponent';
import {
  fetchAllContractCategory,
  fetchRequestApprovals,
} from '../../Services/Approval';
import { bulkUpdateCategory, getDraftById } from '../../Services/Draft';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  selectedRow: any;
}

const EditDraftCategory: React.FC<Props> = ({ open, onClose, selectedRow }) => {
  const { data: draftData } = useQuery({
    queryKey: ['edit_get_draft_by_id', selectedRow[0]],
    queryFn: async () => {
      const response = await getDraftById(selectedRow[0] as string);
      return response as any;
    },
    enabled: !!selectedRow[0] && selectedRow?.length === 1,
  });

  const { data: contractCategory, isLoading: categoryLoading } = useQuery({
    queryKey: ['contract_category_data'],
    queryFn: fetchAllContractCategory,
    select: (response: any) => response.results,
  });

  const { userIsCreator } = useUserData();

  const methods = useForm();
  const { handleSubmit, control, reset } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: creatorCategory } = useQuery({
    queryKey: [
      'edit_contract_category_has_policy',
      draftData?.contractType?.id,
    ],
    queryFn: () => {
      const params = `?approval_type=contract_request&contract=${draftData?.contractType?.id}`;
      return fetchRequestApprovals(params);
    },
    select: (response: any) => {
      const filterCategory = response?.results
        ?.map((item: any) => {
          const categoryItem = item?.contract_category?.filter(
            (category: any) =>
              category?.contract_types?.some(
                (type: any) => type?.id === draftData?.contractType?.id
              )
          );
          return categoryItem;
        })
        .flat();
      const isCategoryRequired = response?.results?.filter(
        (item: any) => !item?.contract_category?.length
      );
      return {
        isCategoryRequired: isCategoryRequired?.length ? false : true,
        creatorCategoryData: filterCategory,
      };
    },
    enabled: !!(draftData?.contractType?.id && userIsCreator),
  });

  const filteredContractCategory = useMemo(() => {
    return contractCategory?.filter((category: any) =>
      category?.contract_types?.some(
        (type: any) => type?.id === draftData?.contractType?.id
      )
    );
  }, [contractCategory, draftData?.contractType?.id]);

  const noContractTypeCategory = useMemo(() => {
    return contractCategory?.filter(
      (category: any) => category?.contract_types?.length === 0
    );
  }, [contractCategory]);

  const combinedContractCategories = useMemo(() => {
    return [
      ...(creatorCategory?.creatorCategoryData || []),
      ...(noContractTypeCategory || []),
      ...(filteredContractCategory || []),
    ];
  }, [
    filteredContractCategory,
    noContractTypeCategory,
    creatorCategory?.creatorCategoryData,
  ]);

  const { mutate: updateDraftData, isPending } = useMutation({
    mutationKey: ['edit-category'],
    mutationFn: bulkUpdateCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      enqueueSnackbar('Successfully updated category!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update category', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = useCallback(
    (data: any) => {
      if (data?.contract_category) {
        const payload = {
          ids: selectedRow,
          ...data,
        };
        updateDraftData(payload);
      }
    },
    [selectedRow, updateDraftData]
  );

  React.useEffect(() => {
    reset({
      contract_category:
        selectedRow?.length === 1 ? draftData?.contract_category?.id : [],
    });
  }, [selectedRow, draftData, reset]);

  return (
    <CustomModal
      maxWidth="md"
      title="Add/Edit Category"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: '500px', padding: '30px 10px' }}>
            <RISelectComponent
              name="contract_category"
              control={control}
              label="Contract Category"
              options={
                userIsCreator
                  ? sortListAlphabetical(creatorCategory?.creatorCategoryData)
                  : sortListAlphabetical(combinedContractCategories)
              }
              loading={categoryLoading}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              type="submit"
              disabled={restrictionStatus?.includes(draftData?.status)}
              loading={isPending}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default EditDraftCategory;
