import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddLinkIcon from '@mui/icons-material/AddLink';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TagIcon from '@mui/icons-material/Tag';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import CreateTemplate from './Component/CreateTemplateForm';
import TemplateTable from './Component/TemplateTable';
import { statisticTabs, TemplateTableTabs } from './StaticData';
import { download_file } from '../../Draft/Component/Helper';
import StaticTab from '../../Draft/Component/TableTabList';
import { IObject } from '../../interfaces/IObject';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import DeleteIcon from '../../RiverusUI/Components/Icons/DeleteIcon';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import {
  deleteTemplate,
  downloadTemplate,
  fetchTemplates,
  fetchTemplateStatistics,
} from '../../Services/Template';

const initialFilter = {
  name: null,
  template_type: null,
  created_by: null,
  group: null,
  contract_type: null,
  tags: null,
};

const AdminTemplate = () => {
  const location = useLocation();

  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [activeTab, setActiveTab] = useState('template');
  const [activeHeader, setActiveHeader] = useState<string>();
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [createTemplate, setOpenCreateTemplate] = useState<boolean>(false);
  const [templateType, setTemplateType] = useState<string>('');
  const [statistics, setStatistics] = useState(statisticTabs);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [templateTableKey, setTemplateTableKey] = useState<number>(0);
  const [refreshKey, setRefreshKey] = useState(false);

  const queryClient = useQueryClient();

  const isAdminPath = useMemo(
    () => location.pathname.includes('admin'),
    [location]
  );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'admin-templates',
      activeTab,
      pageNumber,
      filters,
      sorting,
      activeHeader,
      templateTableKey,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      const filterTypeParam = activeHeader
        ? `&filter_type=${activeHeader}`
        : '';
      const fileTypeParam = `&file_type=${activeTab}`;

      let orderingFields = [];

      if (sorting?.length > 0) {
        orderingFields = sorting?.map(({ field, sort }) =>
          sort === 'asc' ? field : `-${field}`
        );
      } else {
        orderingFields.push('-created_on');
      }
      const sortingParam = orderingFields?.length
        ? `&ordering=${orderingFields.join(',')}`
        : '';
      const params = `?page=${pageNumber + 1}${filterTypeParam}${fileTypeParam}${sortingParam}${filterParam}`;
      return fetchTemplates(params);
    },
  });

  const { data: staticData } = useQuery({
    queryKey: ['template_statistics'],
    queryFn: fetchTemplateStatistics,
  });

  const { mutate: deleteTemplateMutation } = useMutation({
    mutationFn: deleteTemplate,
    onSuccess: (responseData: any) => {
      const associatedWithTemplate =
        responseData?.associated_with_draft_templates;
      queryClient.invalidateQueries({
        queryKey: ['admin-templates'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_statistics'],
      });
      if (associatedWithTemplate?.length > 0) {
        setIsOpenDeleteModal(true);
      } else {
        setOpenDeleteModal(false);
        enqueueSnackbar('Delete template successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
  });

  useEffect(() => {
    if (staticData?.total_templates) {
      const newStatistics = statistics.map((data: any) => {
        for (const key in staticData) {
          if (data.apiKey === key) {
            data.count = staticData[key];
          }
        }
        return data;
      });
      setStatistics(newStatistics);
    }
  }, [staticData]);

  const handleHeaderChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setPageNumber(0);
    setActiveHeader(newValue);
    setFilters({});
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPageNumber(0);
    setActiveTab(newValue);
    setFilters({});
  };

  const handleRefresh = useCallback(() => {
    setTemplateTableKey(Math.random());
    setFilters(initialFilter);
    setSorting([]);
    setRefreshKey((prevKey) => !prevKey);
  }, [queryClient]);

  const handleDelete = (ids: GridRowId[]) => {
    if (ids.length) {
      deleteTemplateMutation({ delete_list: ids });
    }
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setOpenDeleteModal(false);
  };

  const { mutate: downloadTemplateFile } = useMutation({
    mutationKey: ['download_template_file'],
    mutationFn: downloadTemplate,
    onSuccess: (response, payload) => {
      const file_name = `${payload?.template_id}.docx`;
      download_file(response, file_name);
    },
  });

  const handleDownloadFile = (id: string) => {
    downloadTemplateFile({
      template_id: id,
    });
  };

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack direction="row" spacing="10px">
          {isAdminPath && (
            <Stack direction="row" justifyContent="end" alignItems="end">
              <Stack direction="column" spacing="10px">
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Upload
                </Typography>
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: 'riPrimary.500',
                    padding: '4px 8px',
                    borderRadius: '6px',
                    fontSize: '13px',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    height: '4rem',
                    textTransform: 'capitalize',
                    minWidth: '8rem',
                  }}
                  onClick={() => {
                    setOpenCreateTemplate(true);
                    setTemplateType('template');
                  }}
                >
                  <UploadFileIcon
                    fontSize="small"
                    sx={{ marginBottom: '6px' }}
                  />
                  Contract Template
                </Button>
              </Stack>

              <Button
                variant="outlined"
                sx={{
                  padding: '4px 8px',
                  borderRadius: '6px',
                  fontSize: '13px',
                  width: 'fit-content',
                  display: 'flex',
                  flexDirection: 'column',
                  lineHeight: 1,
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  height: '4rem',
                  textTransform: 'capitalize',
                  minWidth: '8rem',
                  mb: 0,
                  '&:hover': {
                    backgroundColor: 'rgba(109, 38, 76, 0.1)',
                    color: '#6D264C',
                  },
                }}
                onClick={() => {
                  setOpenCreateTemplate(true);
                  setTemplateType('support_doc_template');
                }}
              >
                <FileUploadOutlinedIcon
                  fontSize="small"
                  sx={{ marginBottom: '6px' }}
                />
                Support document template
              </Button>
            </Stack>
          )}
          <Stack direction="column" spacing="10px">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              All Templates
            </Typography>
            <Tabs
              value={activeHeader}
              onChange={handleHeaderChange}
              aria-label="My Draft tabs"
              variant="scrollable"
              scrollButtons={false}
              sx={{ marginTop: '4px' }}
            >
              {statistics.map((tab: any, index: number) => (
                <StaticTab
                  key={index}
                  count={tab.count}
                  label={tab.label}
                  tooltip={tab?.tooltip}
                  value={tab.value}
                  {...a11yProps(tab.value)}
                />
              ))}
            </Tabs>
          </Stack>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
            {isAdminPath && selectedRow.length ? (
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                gap={2}
              >
                {activeTab === 'support_doc_template' && (
                  <Button
                    variant="text"
                    sx={{ padding: 0 }}
                    startIcon={<AddLinkIcon />}
                    onClick={() => {
                      setOpenCreateTemplate(true);
                      setTemplateType('edit_linked_template');
                    }}
                  >
                    Edit Linked Contract Type
                  </Button>
                )}
                <Button
                  variant="text"
                  sx={{ padding: 0 }}
                  startIcon={<TagIcon />}
                  onClick={() => {
                    setOpenCreateTemplate(true);
                    setTemplateType('edit_tags');
                  }}
                >
                  Add/Edit Tags
                </Button>
                {selectedRow?.length === 1 && (
                  <IconButton
                    style={{ padding: 0 }}
                    color="primary"
                    onClick={() =>
                      handleDownloadFile(selectedRow?.[0] as string)
                    }
                  >
                    <SaveAltIcon />
                  </IconButton>
                )}
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ) : (
              <Stack direction="column" alignItems="start">
                <IconButton onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="Draft tabs"
                >
                  {TemplateTableTabs.map((tab: any, index: number) => (
                    <Tab
                      key={index}
                      label={tab.label}
                      value={tab.value}
                      {...a11yProps(tab.value)}
                    />
                  ))}
                </Tabs>
              </Stack>
            )}
          </Box>
          <TemplateTable
            setSelectedRow={setSelectedRow}
            data={data}
            isLoading={isLoading || isFetching}
            pageNumber={pageNumber}
            setPageNumberChange={setPageNumber}
            setFilters={setFilters}
            setSorting={setSorting}
            filters={filters}
            selectedRow={selectedRow}
            checkBoxSelection={isAdminPath}
            page={isAdminPath ? 'admin' : 'stylus'}
            activeTab={activeTab}
            refreshKey={refreshKey}
          />
        </Box>
      </Stack>
      {createTemplate && (
        <CreateTemplate
          open={createTemplate}
          onClose={() => {
            setOpenCreateTemplate(false);
            setTemplateType('');
          }}
          type={templateType}
          template_id={templateType?.includes('edit') ? selectedRow : undefined}
          setFilters={setFilters}
          setSorting={setSorting}
          initialFilter={initialFilter}
          setRefreshKey={setRefreshKey}
          setTemplateTableKey={setTemplateTableKey}
        />
      )}
      {isOpenDeleteModal && (
        <ReusableConfirmationModal
          open={isOpenDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Dialog"
          renderAction={
            <Stack alignItems="end">
              <Button
                onClick={handleCloseDeleteModal}
                variant="contained"
                style={{ padding: '5px 15px' }}
              >
                Ok
              </Button>
            </Stack>
          }
        >
          <Stack spacing={1}>
            <Typography>
              {selectedRow?.length === 1 ? 'This' : 'These'} Template cannot be
              deleted because drafts are associated with it.
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}

      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title="Delete Template"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => handleDelete(selectedRow)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected template will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default AdminTemplate;
