import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import ControlledCheckbox from '../../RiverusUI/Components/ControlledCheckbox';
import CustomModal from '../../RiverusUI/Components/CustomModal';
import { supportTemplates } from '../../Services/Template';

interface IProps {
  draftData: any;
  open: boolean;
  onClose: VoidFunction;
  handleRenewConfirmation: (renewal_type: string) => void;
  buttonLoading: boolean;
}

const RenewConfirmation: React.FC<IProps> = ({
  draftData,
  open,
  onClose,
  handleRenewConfirmation,
  buttonLoading,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenWarn, setIsOpenWarn] = useState<boolean>(false);

  const template = watch('template') || '';

  useEffect(() => {
    if (template) {
      setIsOpenWarn(true);
    }
  }, [template]);

  const { data: supportTemplate, isLoading } = useQuery({
    queryKey: ['get-support-templates'],
    queryFn: () => {
      let params = `?support_document_type=Renewal Letter/Agreement&contract_type=${draftData?.contractType?.id}`;
      if (draftData?.contract_category?.id) {
        params += `&contract_category=${draftData?.contractCategory?.id}`;
      }
      const response = supportTemplates(params);
      return response;
    },
    enabled: !!draftData?.contractType?.id,
  });

  const onSubmit = (data: any) => {
    if (
      !data?.template &&
      !data?.request_draft &&
      !data?.counter_party &&
      !data?.support_template
    ) {
      enqueueSnackbar('Please select any one option!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      if (data?.template) {
        handleRenewConfirmation('template');
      }
      if (data?.request_draft) {
        handleRenewConfirmation('request_draft');
      }
      if (data?.counter_party) {
        handleRenewConfirmation('counter_party');
      }
      if (data?.support_template) {
        handleRenewConfirmation('support_template');
      }
    }
  };

  return (
    <React.Fragment>
      <CustomModal
        open={open}
        handleClose={onClose}
        title="Renew Contract"
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={2}>
            <Typography fontWeight="700">Select any one option</Typography>
            {draftData?.createFrom === 'template' && (
              <ControlledCheckbox
                name="template"
                label="Generate renewal draft using the same template from which the contract was created"
                control={control}
                onChange={() => {
                  setValue('request_draft', false);
                  setValue('counter_party', false);
                  setValue('support_template', false);
                }}
              />
            )}

            <ControlledCheckbox
              name="request_draft"
              label="request a renewal draft from legal"
              control={control}
              onChange={() => {
                setValue('template', false);
                setValue('counter_party', false);
                setValue('support_template', false);
              }}
            />

            {draftData?.createFrom === 'counter_party' && (
              <ControlledCheckbox
                name="counter_party"
                label="Create a renewal contract from counter party draft"
                control={control}
                onChange={() => {
                  setValue('template', false);
                  setValue('request_draft', false);
                  setValue('support_template', false);
                }}
              />
            )}

            {draftData?.createFrom === 'template' &&
              supportTemplate?.results?.length > 0 && (
                <ControlledCheckbox
                  name="support_template"
                  label="Use a Renewal Letter/Agreement template"
                  control={control}
                  onChange={() => {
                    setValue('template', false);
                    setValue('request_draft', false);
                    setValue('counter_party', false);
                  }}
                />
              )}

            <Stack direction="row">
              <LoadingButton
                variant="contained"
                type="submit"
                loading={buttonLoading || isLoading}
              >
                Renew Contract
              </LoadingButton>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </CustomModal>
      {isOpenWarn && (
        <Dialog open={isOpenWarn}>
          <Stack width="fit-content" padding={3} spacing={2}>
            <Typography>
              If you choose to renew from template, negotiated changes that may
              be part of the executed version will not automatically reflected.
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="contained" onClick={() => setIsOpenWarn(false)}>
                Ok
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default RenewConfirmation;
