export const appOption = [
  {
    name: 'Stylus',
    id: 'Stylus',
  },
  {
    name: 'Umbrella',
    id: 'Umbrella',
  },
];

export const status_bg_color: any = {
  'Not Started': '#f7eebf70',
  'In Progress': '#deebff',
  Completed: '#e5efdd',
  'Past Due Date': '#ed91211c',
  Flagged: '#ffefef',
};

export const status_chip_color: any = {
  'Not Started': '#ceb251',
  'In Progress': '#2b5fb0',
  Completed: '#3f7713',
  'Past Due Date': '#ca8531',
  Flagged: '#C9372C',
};

export const tabMeta = [
  { value: 'urgent', label: 'Urgent', tooltip: 'High priority reminders' },
  { value: 'this_week', label: 'This Week', tooltip: 'Due this week' },
  { value: 'this_month', label: 'This Month', tooltip: 'Due this month' },
  { value: 'upcoming', label: 'Upcoming', tooltip: 'Coming later' },
  { value: 'expired', label: 'Expired', tooltip: 'Past due date' },
];
