import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import { IObject } from '../../../interfaces/IObject';
import DataGridTable from '../../../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../../../RiverusUI/DataGrid/GridCellExpand';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import TableChipList from '../../../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../../../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../../RiverusUI/DataGrid/TableSearchInputComponent';
import TableSearchSelectInput from '../../../RiverusUI/DataGrid/TableSearchSelectInput';
import {
  fetchAllContractCategory,
  fetchGroups,
  getUsers,
} from '../../../Services/Approval';
import { getContractTypes } from '../../../Services/Template';
import { QueryKeyGenerator } from '../../../Utils/QueryKeyGenerator';
import CreateApprovalComponent from '../CreateApprovalForm/CreateApprovalComponent';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  selectedRow: GridRowId[];
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  deleteApproval: (ids: GridRowId[]) => void;
  editApproval: (id: GridRowId) => void;
  copyApproval: (id: GridRowId) => void;
  filters?: IObject;
}

const ApprovalTable = (props: Props) => {
  const {
    setSelectedRow,
    selectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    deleteApproval,
    editApproval,
    copyApproval,
    filters,
  } = props;

  const isActionDisabled = useMemo<boolean>(
    () => selectedRow.length > 1,
    [selectedRow]
  );

  const [searchApproval, setSearchApproval] = useState<boolean>(false);
  const [searchContract, setSearchContract] = useState<boolean>(false);
  const [searchCategory, setSearchCategory] = useState<boolean>(false);
  const [searchAuthor, setSearchAuthor] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [openCreateApproval, setOpenCreateApproval] = useState<boolean>(false);
  const [editCopyDetailId, setEditCopyDetailId] = useState<any>();
  const [isViewPolicy, setIsViewPolicy] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { data: contracts } = useQuery({
    queryKey: QueryKeyGenerator.getChoiceFetchingQuery(),
    queryFn: getContractTypes,
    select: (response: any) => {
      return response?.results;
    },
  });

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response: any) => {
      const users = response?.users || [];
      for (let i = 0; i < users.length; i++) {
        const name = users[i].first_name + ' ' + users[i].last_name;
        users[i].name = name;
      }
      return users;
    },
  });

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups,
  });

  const { data: contractCategory } = useQuery({
    queryKey: ['contractCategory'],
    queryFn: fetchAllContractCategory,
    select: (response: any) => response.results,
  });

  const handleCloseOfViewPolicy = () => {
    setEditCopyDetailId(null);
    setOpenCreateApproval(false);
  };

  const handleOpenViewPolicy = (id: string) => {
    setEditCopyDetailId(id);
    setOpenCreateApproval(true);
    setIsViewPolicy(true);
  };

  const handleGroupClick = useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const handleContractTypeClick = React.useCallback(
    (contractType: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contract: contractType,
      }));
      if (contractType?.length) {
        setPageNumberChange(0);
      }
      setSearchContract(true);
    },
    [handleFilterChange]
  );

  const handleCategoryClick = React.useCallback(
    (category: any) => {
      setFilters((prev: IObject) => ({
        ...prev,
        contract_category: category,
      }));
      if (category?.length) {
        setPageNumberChange(0);
      }
      setSearchCategory(true);
    },
    [setFilters]
  );

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'policy_name',
        headerName: 'Approval Name',
        minWidth: 200,
        flex: 1,
        sortable: !searchApproval,
        renderHeader: () => {
          return searchApproval ? (
            <TableSearchInputComponent
              key="approval"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, policy_name: null }));
                setSearchApproval(false);
              }}
              placeholder="Search Name"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('policy_name', e.target.value)
              }
            />
          ) : (
            <Stack onClick={(e) => e.stopPropagation()}>
              <TableHeaderWithSearch
                title="Approval Name"
                setIsSearch={setSearchApproval}
              />
            </Stack>
          );
        },
        renderCell: (Params) => {
          return (
            <GridCellExpand
              value={Params?.row?.policy_name}
              width={Params?.colDef?.computedWidth}
              onClick={() => handleOpenViewPolicy(Params.row?.id)}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      {
        field: 'created_by',
        headerName: 'Author',
        minWidth: searchAuthor ? 260 : 180,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return (
            <NameAvatar
              firstName={params.row.created_by.first_name}
              lastName={params.row.created_by.last_name}
            />
          );
        },
        renderHeader: () => {
          return searchAuthor ? (
            <TableSearchInputComponent
              key="author"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, created_by: null }));
                setSearchAuthor(false);
              }}
              placeholder="Search Author"
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key="author"
                  label="Select Author"
                  options={sortListAlphabetical(authorData)}
                  valueKey="id"
                  value={filters?.created_by ? filters.created_by : []}
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('created_by', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        created_by: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Author"
              setIsSearch={setSearchAuthor}
            />
          );
        },
      },
      {
        field: 'groups',
        headerName: 'Prescribing Dept.',
        maxWidth: 300,
        width: 250,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          const selectedFilters: any = filters?.groups || [];
          let reOrderGroups = params?.row?.groups || [];
          if (selectedFilters?.length) {
            const filterSelectedGroups = reOrderGroups?.filter((item: any) =>
              selectedFilters?.includes(item?.id)
            );
            const filterNotSelectedGroups = reOrderGroups?.filter(
              (item: any) => !selectedFilters?.includes(item?.id)
            );
            reOrderGroups = [
              ...filterSelectedGroups,
              ...filterNotSelectedGroups,
            ];
          }
          return (
            <TableChipList
              id={`groups-${params?.row?.id}`}
              labelKey="name"
              valueKey="id"
              chipColor="#DCBDE7"
              list={reOrderGroups}
              title={`${params?.row?.policy_name} group`}
              onClick={handleGroupClick}
            />
          );
        },
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="groups"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, groups: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Prescribing Dept."
              selectedValue={filters?.groups ? filters.groups : []}
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.groups)
                      ? (filters?.groups?.[0] as string)
                      : 'groups'
                  }
                  options={sortListAlphabetical(groupData)}
                  label="Select Prescribing Dept."
                  labelKey="name"
                  valueKey="id"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('groups', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        groups: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Prescribing Dept."
              setIsSearch={setSearchGroups}
            />
          );
        },
      },
      {
        field: 'contract',
        headerName: 'Contract Type',
        maxWidth: 300,
        minWidth: 260,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const selectedFilters: any = filters?.contract || [];
          let reOrderContract = params?.row?.contract || [];
          if (selectedFilters?.length) {
            const filterSelectedContract = reOrderContract?.filter(
              (item: any) => selectedFilters?.includes(item?.id)
            );
            const filterNotSelectedContract = reOrderContract?.filter(
              (item: any) => !selectedFilters?.includes(item?.id)
            );
            reOrderContract = [
              ...filterSelectedContract,
              ...filterNotSelectedContract,
            ];
          }
          return (
            <TableChipList
              id={`Contract-${params?.row?.id}`}
              labelKey="displayName"
              valueKey="id"
              chipColor="#C4DBFF"
              list={reOrderContract}
              title={`${params?.row?.policy_name} Contract Type`}
              onClick={handleContractTypeClick}
            />
          );
        },
        renderHeader: () => {
          return searchContract ? (
            <TableSearchInputComponent
              key="contractType"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contract: null }));
                setSearchContract(false);
              }}
              placeholder="Search contract type"
              selectedValue={filters?.contract ? filters.contract : []}
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.contract)
                      ? (filters?.contract?.[0] as string)
                      : 'contract'
                  }
                  label="Select contract type"
                  labelKey="displayName"
                  options={sortListAlphabetical(contracts, 'displayName')}
                  valueKey="id"
                  value={filters?.contract ? filters.contract : []}
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('contract', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        contract: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Type"
              setIsSearch={setSearchContract}
            />
          );
        },
      },
      {
        field: 'contract_category',
        headerName: 'Contract Category',
        maxWidth: 300,
        minWidth: 260,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const selectedFilters: any = filters?.contract_category || [];
          let reOrderCategory = params?.row?.contract_category || [];
          if (selectedFilters?.length) {
            const filterSelectedCategory = reOrderCategory?.filter(
              (item: any) => selectedFilters?.includes(item?.id)
            );
            const filterNotSelectedCategory = reOrderCategory?.filter(
              (item: any) => !selectedFilters?.includes(item?.id)
            );
            reOrderCategory = [
              ...filterSelectedCategory,
              ...filterNotSelectedCategory,
            ];
          }
          return (
            <TableChipList
              id={`Category-${params?.row?.id}`}
              labelKey="name"
              valueKey="id"
              chipColor="#C4DBFF"
              list={reOrderCategory}
              title={`${params?.row?.policy_name} Contract Category`}
              onClick={handleCategoryClick}
            />
          );
        },
        renderHeader: () => {
          return searchCategory ? (
            <TableSearchInputComponent
              key="contract_category"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contract_category: null }));
                setSearchCategory(false);
              }}
              placeholder="Search contract category"
              selectedValue={
                filters?.contract_category ? filters.contract_category : []
              }
              renderCustomInput={() => (
                <TableSearchSelectInput
                  key={
                    isArray(filters?.contract_category)
                      ? (filters?.contract_category?.[0] as string)
                      : 'contract_category'
                  }
                  label="Select contract category"
                  options={sortListAlphabetical(contractCategory)}
                  valueKey="id"
                  value={
                    filters?.contract_category ? filters.contract_category : []
                  }
                  onChange={(value: string[]) => {
                    if (value.length > 0) {
                      handleFilterChange('contract_category', value);
                    } else {
                      setFilters((prev: IObject) => ({
                        ...prev,
                        contract_category: null,
                      }));
                    }
                  }}
                  isLoading={isLoading}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Category"
              width="140px"
              setIsSearch={setSearchCategory}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: 'More Actions',
        type: 'actions',
        minWidth: 150,
        getActions: (params) => [
          <GridActionsCellItem
            key="actions"
            icon={<EditOutlinedIcon />}
            label="Edit"
            disabled={isActionDisabled}
            onClick={() => {
              editApproval(params?.id);
            }}
          />,
          // <GridActionsCellItem
          //   icon={<CopyAllOutlinedIcon />}
          //   label="Copy"
          //   disabled={isActionDisabled}
          //   onClick={() => {
          //     copyApproval(params.id);
          //   }}
          // />,
          <GridActionsCellItem
            key="delete"
            icon={<DeleteOutlineOutlinedIcon />}
            label="Delete"
            disabled={isActionDisabled}
            onClick={() => deleteApproval([params.id])}
          />,
        ],
      },
    ],
    [
      authorData,
      contracts,
      contractCategory,
      copyApproval,
      deleteApproval,
      editApproval,
      groupData,
      handleFilterChange,
      isActionDisabled,
      searchApproval,
      searchAuthor,
      searchContract,
      searchCategory,
      searchGroups,
      setFilters,
      isLoading,
    ]
  );

  return (
    <>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
        checkboxSelection
      />
      {openCreateApproval && (
        <CreateApprovalComponent
          open={openCreateApproval}
          onClose={handleCloseOfViewPolicy}
          editDetails={!isViewPolicy}
          editCopyDetailId={editCopyDetailId}
          isViewPolicy={isViewPolicy}
          setIsViewPolicy={setIsViewPolicy}
        />
      )}
    </>
  );
};

export default ApprovalTable;
