import React, { useCallback, useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import saveAs from 'file-saver';

import DocxEditingComponent from './DocxEditingComponent';
import DocxViewerComponent from './DocxViewerComponent';
import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from '../../Authentication/Actions/authentication';
import { getSignatureTypeExt } from '../../ExternalUserFlow/Services/Draft';
import { getS3ReferencePresignedURL } from '../../Services/Draft';
import { getSignatureType } from '../../Services/setting';
import { draftStatus } from '../State/DraftState';

interface IProps {
  draftData: any;
  fileLink: string;
  setFileLink?: any;
  handleOpenClauseLibraryDrawer?: VoidFunction;
  canEdit?: boolean;
  instance: any;
  setInstance: any;
  dropPoint?: any;
  setDropPoint?: any;
  addedSignatureFields?: any;
  setAddedSignatureFields?: any;
  referenceDocData?: any;
  setViewDocDetails?: any;
  isExternal?: boolean;
  setDocumentLoading?: any;
  internalSignatoryId?: string;
  externalSignatoryId?: string;
}

const PdfView: React.FC<IProps> = ({
  draftData,
  fileLink,
  setFileLink,
  canEdit,
  instance,
  setInstance,
  setDropPoint,
  setAddedSignatureFields,
  handleOpenClauseLibraryDrawer,
  referenceDocData,
  setViewDocDetails,
  isExternal,
  setDocumentLoading,
  internalSignatoryId,
  externalSignatoryId,
}) => {
  const [referenceDoc, setReferenceDoc] = useState<any>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [docFileName, setDocFileName] = useState<any>();

  const zoomOut = () => {
    const zoom = instance.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      instance.UI.setZoomLevel(zoom);
    }
  };

  const zoomIn = () => {
    const zoom = instance.UI.getZoomLevel() + 0.25;
    instance.UI.setZoomLevel(zoom);
  };

  const search = useCallback(() => {
    const { UI } = instance;
    UI.searchText('', {
      caseSensitive: true,
      wholeWord: true,
    });
  }, [instance]);

  const drop = useCallback(
    (e: any, docViewer: any) => {
      const scrollElement = docViewer.getScrollViewElement();
      const scrollLeft = scrollElement.scrollLeft || 0;
      const scrollTop = scrollElement.scrollTop || 0;
      setDropPoint({ x: e.offsetX + scrollLeft, y: e.offsetY + scrollTop });
      e.preventDefault();
      return false;
    },
    [setDropPoint]
  );

  const dragOver = useCallback((e: any) => {
    e.preventDefault();
    return false;
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: signatureType } = useQuery({
    queryKey: ['signature-method'],
    queryFn: isExternal ? getSignatureTypeExt : getSignatureType,
    select: (response: any) => response.results?.[0],
    enabled: draftData?.status === draftStatus.SIGNATURE_PENDING,
  });

  const { mutate: viewReferenceDocument } = useMutation({
    mutationKey: ['view_reference_document_link'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: (response: any) => {
      const url = response?.response?.presigned_url;
      const fileExtension = url.split('.').pop()?.split('?')[0] || 'pdf';
      if (isDownloading) {
        fetch(url)
          .then((r) => r.blob())
          .then((blobData) => {
            saveAs(
              blobData,
              `${referenceDoc?.file_name || referenceDoc?.contractName + `.${fileExtension}`}`
            );
          });
      } else if (!isDownloading && setViewDocDetails) {
        setViewDocDetails({
          access_url: url,
          dataObject: referenceDoc,
        });
      }
      setFileLink?.(url);
    },
  });

  const handleViewDocDetails = (data: any) => {
    setIsDownloading(false);
    setReferenceDoc(data?.dataObject);
    viewReferenceDocument({
      body: {
        file_type: 'view_document',
        link:
          data?.dataObject?.link ||
          data?.dataObject?.template_link ||
          data?.access_url,
      },
    });
  };

  const handleDownload = (data: any) => {
    setIsDownloading(true);
    setReferenceDoc(data?.dataObject);
    viewReferenceDocument({
      body: {
        file_type: 'view_document',
        link:
          data?.dataObject?.link ||
          data?.dataObject?.template_link ||
          data?.access_url,
      },
    });
  };

  useEffect(() => {
    if (referenceDoc?.file_name || referenceDoc?.contractName) {
      setSessionStorage(
        'doc_file_name',
        referenceDoc?.file_name || referenceDoc?.contractName
      );
    }
  }, [referenceDoc?.file_name, referenceDoc?.contractName]);

  useEffect(() => {
    if (getSessionStorage('isPageRefreshed')) {
      removeSessionStorage('doc_file_name');
    } else {
      setSessionStorage('isPageRefreshed', 'true');
      const storedReferenceDoc = getSessionStorage('doc_file_name');
      if (storedReferenceDoc) {
        setDocFileName(storedReferenceDoc);
      }
    }
    return () => {
      removeSessionStorage('isPageRefreshed');
    };
  }, []);

  return (
    <React.Fragment>
      <Stack direction="row">
        <Stack
          sx={{
            background: '#88305F14',
            borderRadius: '25px',
            padding: '2px 10px',
            flex: 1,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <IconButton color="primary" onClick={search}>
            <SearchIcon /> Search
          </IconButton>
          {referenceDocData?.length > 0 && !canEdit && (
            <Stack alignSelf="center" sx={{ padding: '4px' }}>
              <Badge
                badgeContent={referenceDocData?.length}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#A0597F',
                    color: '#fff',
                  },
                }}
              >
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  id="menu-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  onClick={handleClick}
                  variant="outlined"
                  sx={{ padding: '2px 10px', margin: '-2px -4px' }}
                >
                  {docFileName ||
                    draftData?.contractName ||
                    draftData?.file_name}
                </Button>
              </Badge>
            </Stack>
          )}
          <Menu
            id="menu-button"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {referenceDocData?.map((referenceDoc: any, index: number) => (
              <MenuItem
                key={index}
                onClick={handleClose}
                sx={{ justifyContent: 'space-between' }}
              >
                <Button
                  variant="text"
                  style={{
                    padding: 0,
                  }}
                  onClick={() => handleViewDocDetails(referenceDoc)}
                >
                  {referenceDoc?.dataObject?.file_name ||
                    referenceDoc?.dataObject?.contractName}
                  {referenceDoc?.dataObject?.terminate_status ? (
                    referenceDoc?.dataObject?.is_renewed ===
                    'Under_Terminate' ? (
                      <Chip label="Under Terminate" sx={{ ml: 1 }} />
                    ) : (
                      <Chip label="Terminated" sx={{ ml: 1 }} />
                    )
                  ) : (
                    <>
                      {referenceDoc?.dataObject?.is_renewed ===
                        'Under_Renewal' && (
                        <Chip
                          label="Under Renewal"
                          sx={{
                            ml: 1,
                            background: '#C4DBFF',
                            color: '#1D192B',
                          }}
                        />
                      )}
                      {referenceDoc?.dataObject?.is_renewed === 'Renewed' && (
                        <Chip
                          label="Renewed Version Available"
                          sx={{
                            ml: 1,
                            background: '#CDE7BD',
                            color: '#184d14',
                          }}
                        />
                      )}
                    </>
                  )}
                </Button>
                <Button
                  sx={{ padding: '6px', minWidth: 'unset' }}
                  onClick={() => handleDownload(referenceDoc)}
                >
                  <FileDownloadOutlinedIcon />
                </Button>
              </MenuItem>
            ))}
          </Menu>
          <Stack direction="row">
            <IconButton color="primary" onClick={zoomIn}>
              <ZoomInIcon />
            </IconButton>
            <IconButton color="primary" onClick={zoomOut}>
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Stack>
        {canEdit && (
          <Button variant="contained" onClick={handleOpenClauseLibraryDrawer}>
            Open Clause Library
          </Button>
        )}
      </Stack>
      <Box marginTop={1}>
        {canEdit ? (
          <DocxEditingComponent
            key={`${fileLink || ''}-edit`}
            fileLink={fileLink}
            setInstance={setInstance}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            drop={drop}
            draftDetails={draftData}
          />
        ) : (
          <DocxViewerComponent
            key={fileLink}
            fileLink={fileLink}
            setInstance={setInstance}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            drop={drop}
            draftDetails={draftData}
            instance={instance}
            isExternal={isExternal}
            setDocumentLoading={setDocumentLoading}
            internalSignatoryId={internalSignatoryId}
            externalSignatoryId={externalSignatoryId}
            signatureFormat={signatureType?.signature_format}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default PdfView;
