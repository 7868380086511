import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import CreateGroupsModal from './CreateGroupsModal';
import { IObject } from '../interfaces/IObject';
import DataGridTable from '../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../RiverusUI/DataGrid/GridCellExpand';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  selectedRow: GridRowId[];
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  deleteGroup: (ids: GridRowId[]) => void;
  editGroup: any;
  filters?: IObject;
  editDetails?: boolean;
}

const GroupsTable = (props: Props) => {
  const {
    setSelectedRow,
    selectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    deleteGroup,
    editGroup,
    filters,
    editDetails,
  } = props;

  const isActionDisabled = useMemo<boolean>(
    () => selectedRow.length > 1,
    [selectedRow]
  );
  const [openCreateGroup, setOpenCreateGroup] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleCloseOfViewPolicy = () => {
    setOpenCreateGroup(false);
  };

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'groups',
        headerName: 'Groups',
        flex: 8,
        minWidth: 80,
        sortable: false,
        renderCell: (Params) => {
          return (
            <GridCellExpand
              value={Params?.row?.name}
              width={Params?.colDef?.computedWidth}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 2,
        minWidth: 150,
        getActions: (params) => [
          // <GridActionsCellItem
          //   key="edit"
          //   icon={<EditOutlinedIcon />}
          //   label="Edit"
          //   disabled={isActionDisabled}
          //   onClick={() => editGroup(params?.row)}
          // />,
          <GridActionsCellItem
            key="delete"
            icon={<DeleteOutlineOutlinedIcon />}
            label="Delete"
            disabled={isActionDisabled}
            onClick={() => deleteGroup([params?.row?.id])}
          />,
        ],
      },
    ],
    [
      data,
      filters,
      handleFilterChange,
      setFilters,
      isActionDisabled,
      editGroup,
      deleteGroup,
    ]
  );

  return (
    <>
      <DataGridTable
        rows={data || []}
        columns={columns}
        rowCount={data?.length}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        checkboxSelection={false}
        hideFooterPagination
      />
      {openCreateGroup && (
        <CreateGroupsModal
          open={openCreateGroup}
          onClose={handleCloseOfViewPolicy}
          editDetails={editDetails}
        />
      )}
    </>
  );
};

export default GroupsTable;
