import axios from 'axios';

import AUTH0 from '../../auth_config';
import { getSessionStorage } from '../../Authentication/Actions/authentication';
import { PresignedDataField } from '../../Draft/State/DraftState';

const APIPaths = {
  draftBaseUrl: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
  getRequisition:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/requisition_form/',
  getSignatories:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Signatories&assignee_type=internal',
  getCompareVersion:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/compare_version/',
  getTemplates: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/templates/',
  getChecklist:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_checklist/',
  createChecklist: process.env.REACT_APP_RIVERUS_CLM_API + 'admin/checklist/',
  uploadDocUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft_checklist_supportdoc/',
  getContractTemplates: process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/',
  getTemplatePresignedURL: `${AUTH0.apiBasePath}stylus/templates/aws/presigned-url/`,
  getS3PresignedURL: `${AUTH0.apiBasePath}stylus/aws/presigned-url/`,
  getOptionalFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/optional_field/',
  getUsersExternalFlow:
    process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/',
  saveCompleteDraftFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/bulk_optional_field/',
  getFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/get_field',
  getCollaborators:
    process.env.REACT_APP_RIVERUS_CLM_API + 'users/?role=Collaborators',
  getApprovals:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval/',
  approvalComments:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval_comment/',
  checklistComments:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/checklist-comment/',
  requestApproval:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/request_approval/',
  getDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_deviation/',
  getMergeTagDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/merge-tag-deviation/',
  draft_deviation:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_deviation/',
  draft_approval:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval/',
  getContractPreSignUrl:
    process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/aws/presigned-url/',
  downloadFileUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft_checklist_supportdoc/',
  reminderUrl: process.env.REACT_APP_RIVERUS_CLM_API + 'reminder/',
  sendEmail: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/send-email/',
  umbrellaSupportDocs:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'contracts/support-documents-contract/',
  bankStamps: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/stamp-bank/',
  signatureType:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/signature-type/',
  editContract: process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/',
};

const APIConfig = () => ({
  headers: {
    Authorization: `Custom ${getSessionStorage('external_auth_token')}`,
  },
});

const axiosConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};

export const createDraft = async (payload: any) => {
  return await axios.post(APIPaths.draftBaseUrl, payload, APIConfig());
};

export const editDraftDataExternal = async (payload: any) => {
  const response = await axios.patch(
    `${APIPaths.draftBaseUrl}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
  return response;
};

export const fetchExternalCollaborators = async () => {
  const res = await fetch(`${APIPaths.getCollaborators}`, APIConfig());
  return res.json();
};

export const fetchExternalTemplate = async () => {
  const response = await fetch(APIPaths?.getTemplates, APIConfig());
  return response.json();
};

export const updateExternalDraft = (id: string, body: any) => {
  return axios.put(`APIPaths.createApproval${id}/`, body, APIConfig());
};

export const fetchExternalRequisitionVersionHistory = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getRequisition}?search=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalChecklistData = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getChecklist}?draft=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalRequisitionVersionById = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getRequisition}${id}/`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalDraftVersionId = async (id: string) => {
  const response = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return response.json();
};

export const updateExternalVersion = (id: string) => {
  const response = axios.patch(
    `${APIPaths.getRequisition}/${id}/`,
    {
      draft_created: true,
    },
    APIConfig()
  );
  return response;
};

export const updateExternalChecklist = async (payload: any) => {
  const response = await axios.put(
    `${APIPaths?.getChecklist}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
  return response;
};

export const fetchExternalSupportDocsDownloadUrl = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.downloadFileUrl}${id}/download-checklist/`,
    APIConfig()
  );
  return response.json();
};

export const createExternalChecklist = (payload: any) => {
  const response = axios.post(APIPaths?.createChecklist, payload, APIConfig());
  return response;
};

export const getExternalRequisitionById = async (id: string) => {
  const res = await fetch(`${APIPaths.getRequisition}${id}/`, APIConfig());
  return res.json();
};

export const fetchSignatoriesListExternal = async () => {
  const res = await fetch(`${APIPaths.getSignatories}`, APIConfig());
  return res.json();
};

export const fetchContractPreSignUrlExternal = async (fileInfo: any) => {
  return await axios
    .post(APIPaths?.getContractPreSignUrl, { ...fileInfo }, APIConfig())
    .then((response) => response?.data)
    .catch((error) => {
      const data = {
        error,
        file: fileInfo,
      };
      throw data;
    });
};

export const fetchPresignedUrlExternal = async (payload: any) => {
  return await axios
    .post(APIPaths?.getS3PresignedURL, payload, APIConfig())
    .then((response) => response?.data)
    .catch((err) => {
      throw err;
    });
};

export const updateSignatoriesListExternal = async (
  payload: any
): Promise<any> => {
  return await axios
    .patch(`${APIPaths?.draftBaseUrl}${payload.id}/`, payload.body, APIConfig())
    .then((response) => response?.data)
    .catch((err) => {
      throw err;
    });
};

export const getDraftById = async (id: string) => {
  const res = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return res.json();
};

export const saveExternalDraft = async (payload?: any) => {
  return await axios.post(
    `${APIPaths.draftBaseUrl}bulk_optional_field/`,
    payload,
    APIConfig()
  );
};

export const uploadNewDocumentExternal = async (payload: any) => {
  return axios
    .post(`${APIPaths?.uploadDocUrl}`, payload, APIConfig())
    .then((response) => response?.data)
    .catch((err) => {
      throw err;
    });
};

export const deleteSupportDocFromChecklistExternal = (id: string) => {
  const response = axios.delete(`${APIPaths?.uploadDocUrl}${id}/`, APIConfig());
  return response;
};

export const addExternalChecklistComments = async (payload: any) => {
  return await axios.post(APIPaths.checklistComments, payload, APIConfig());
};

export const addExternalChecklistBulkComments = async (payload: any) => {
  return await axios.post(
    `${APIPaths.checklistComments}comments-bulk-update/`,
    payload,
    APIConfig()
  );
};

export const upload_file_in_s3_bucket_external = async ({
  presignedPostData,
  file,
  onHandleFileProgress,
}: {
  presignedPostData: any;
  file?: any;
  onHandleFileProgress?: any;
}) => {
  const { fields, url } = presignedPostData;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof PresignedDataField]);
  });

  file && formData.append('file', file);

  const config = onHandleFileProgress
    ? { axiosConfig, ...onHandleFileProgress }
    : axiosConfig;

  return await axios.post(url, formData, config).then(() => presignedPostData);
};

export const fetchExternalTemplateById = async (id: string) => {
  const response = await fetch(`${APIPaths?.getTemplates}${id}/`, APIConfig());
  return response.json();
};

export const fetchExternalExecutedContracts = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getContractTemplates}?contract_type=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalEarlierDrafts = async (name: string) => {
  const response = await fetch(
    `${APIPaths?.draftBaseUrl}get_latest/?filter_parameter=Group&filter_type=earlier_draft&contractType=${name}`,
    APIConfig()
  );
  return response.json();
};

export const getS3PresignedUrlExternal = async (fileInfo: any) => {
  return await axios
    .post(
      fileInfo.file_type === 'template'
        ? APIPaths.getTemplatePresignedURL
        : APIPaths.getS3PresignedURL,
      { ...fileInfo },
      APIConfig()
    )
    .then((response) => response?.data)
    .catch((err) => {
      throw err;
    });
};

export const fetchOptionalFieldsExternal = async (draft_uuid: string) => {
  const res = await fetch(
    `${APIPaths.getOptionalFields}${draft_uuid}/`,
    APIConfig()
  );
  return res.json();
};

export const getUsersExternalFlow = async () => {
  const res = await fetch(`${APIPaths.getUsersExternalFlow}`, APIConfig());
  return res.json();
};

export const fetchGetFieldsExternal = async (id: string) => {
  const res = await fetch(
    `${APIPaths.getFields}?contract_type=${id}`,
    APIConfig()
  );
  return res.json();
};

export const addExternalDraftApprovalComments = async (payload: any) => {
  return await axios.post(APIPaths.approvalComments, payload, APIConfig());
};

export const editExternalBulkReminder = async (payload: any) => {
  return await axios.post(
    `${APIPaths.reminderUrl}reminder-update/`,
    payload,
    APIConfig()
  );
};

export const editExternalReminder = async (payload: any) => {
  const { id, body } = payload;
  return await axios.patch(`${APIPaths?.reminderUrl}${id}/`, body, APIConfig());
};

export const sendEmailExt = async (payload: any) => {
  return await axios.post(APIPaths?.sendEmail, payload, APIConfig());
};

export const contractSupportDocsExt = async (payload: any) => {
  return await axios.post(APIPaths?.umbrellaSupportDocs, payload, APIConfig());
};

export const fetchExternalStamps = async (params = ''): Promise<any> => {
  const res = await fetch(`${APIPaths?.bankStamps}${params}`, APIConfig());
  return res.json();
};

export const updateExtStampPaper = async (payload: any) => {
  return await axios.patch(
    `${APIPaths?.bankStamps}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
};

export const getSignatureTypeExt = async () => {
  const response = await fetch(`${APIPaths?.signatureType}`, APIConfig());
  return response.json();
};

export const updateExtEditContract = async (payload: any) => {
  return await axios.patch(
    `${APIPaths?.editContract}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
};

export const deleteExternalChecklistComment = (id: string) => {
  const response = axios.delete(
    `${APIPaths.checklistComments}${id}/`,
    APIConfig()
  );
  return response;
};
